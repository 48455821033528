import React, { useEffect, useState } from 'react';
import { FaGooglePlay } from 'react-icons/fa';
import { MdAccessAlarm } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import DrawerNavbar from '../../../../components/navbar/drawerNavbar';
import MiddleContainerHeader from '../../../../components/middleContainerHeader';
import SignInButton from '../../../../components/signInButton';
import { getActiveRoomsApi, getRoomByIdApi } from '../../../../utils/api';
import RoomsCard from '../../../../components/roomCard';

export default function Rooms() {
    const { roomId } = useParams();

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [roomsData, setRoomsData] = useState([]);
    const [roomData, setRoomData] = useState(null);
    let drawerNavbar;

    if (isDrawerOpen) {
        drawerNavbar = <DrawerNavbar click={() => setDrawerOpen(!isDrawerOpen)} />;
    }

    useEffect(() => {
        if (roomId) {
            getRoomByIdApi(roomId).then((data) => setRoomData(data));
        } else {
            getActiveRoomsApi().then((data) => setRoomsData(data));
        }
    }, [roomId]);

    if (!roomId) {
        return (
            <div className={styles.roomContainer}>
                <MiddleContainerHeader
                    header="Rooms"
                    onClick={() => setDrawerOpen(!isDrawerOpen)}
                />
                <div className={styles.roomsContent}>
                    {roomsData && roomsData.map((data) => <RoomsCard key={data.id} data={data} />)}
                </div>
                {drawerNavbar}
            </div>
        );
    }
    return (
        roomData && (
            <div className={styles.roomContainer}>
                <MiddleContainerHeader header="Room" onClick={() => setDrawerOpen(!isDrawerOpen)} />
                <div className={styles.roomContent}>
                    <img
                        className={styles.hostPic}
                        alt="Host"
                        src={roomData.img}
                        style={
                            roomData.scheduledText
                                ? { gridRow: '1 / 4 span' }
                                : { gridRow: '1 / 3 span' }
                        }
                    />
                    <p className={styles.host}>Host: {roomData.host}</p>
                    <p className={styles.title}>{roomData.title}</p>
                    <p className={styles.description}>{roomData.description}</p>
                    {roomData.scheduledText && (
                        <div className={styles.schedule}>
                            <MdAccessAlarm size={30} />
                            {roomData.scheduledText}
                        </div>
                    )}
                </div>
                <h3>FOR ROOM FUNCTIONALITY</h3>
                <SignInButton
                    onClick={() =>
                        window.open(
                            'https://play.google.com/store/apps/details?id=com.justbaat.android',
                            '_blank'
                        )
                    }
                    icon={<FaGooglePlay size={35} />}
                    label_1="GET APP ON"
                    label_2="Google Play"
                />
                {drawerNavbar}
            </div>
        )
    );
}
