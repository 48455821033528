// eslint-disable-next-line import/prefer-default-export
export const ActionTypes = {
    SET_USERS: 'SET_USERS',
    SET_RECOMMENDED_FEED: 'SET_RECOMMENDED_FEED',
    SET_FEED_BY_USER: 'SET_FEED_BY_USER',
    SET_AUDIO_SHOT_BY_ID: 'SET_AUDIO_SHOT_BY_ID',
    SET_IS_PLAYING: 'SET_IS_PLAYING',
    SET_AUDIO_SHOT_PLAYER_QUEUE: 'SET_AUDIO_SHOT_PLAYER_QUEUE',
    SELECTED_AUDIO_SHOT: 'SELECTED_AUDIO_SHOT',
    SELECTED_REPLY: 'SELECTED_REPLY'
};
