import React, { useState } from 'react';
import { GoVerified } from 'react-icons/go';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { setSignInOptionsVisible } from '../../redux/actions/authActions';

export default function ExplorerProfile(props) {
    const { isLoggedIn } = useSelector((state) => state).authReducer;
    const dispatch = useDispatch();
    const history = useHistory();
    const [isFollower, setFollower] = useState(false);
    const { style, data } = props;

    return (
        <div
            className={styles.publicProfileCard}
            style={style}
            onClick={() => history.push(`/app/p/${data.user_id}`)}
        >
            <img className={styles.cardProfilePic} alt="Profile Pic" src={data.picture} />
            <div className={styles.profileName}>
                <label>{data.name}</label>
                {data.verified && <GoVerified size=".7em" color="#1C9DEAFF" />}
            </div>
            <div className={styles.profileFollow}>
                <div className={styles.profileFollowers}>
                    <label>{data.totalFollowers ? data.totalFollowers : 0}</label>
                    <span>Followers</span>
                </div>
                <div className={styles.profileFollowers}>
                    <label>{data.totalFollwing ? data.totalFollwing : 0}</label>
                    <span>Following</span>
                </div>
            </div>
            <button
                className={styles.cardFollowButton}
                onClick={(e) => {
                    e.stopPropagation();
                    setFollower(!isFollower);
                    if (!isLoggedIn) {
                        dispatch(setSignInOptionsVisible(true));
                    }
                }}
            >
                {/* eslint-disable-next-line no-nested-ternary */}
                {!isLoggedIn ? 'Follow' : isFollower ? 'Unfollow' : 'Follow'}
            </button>
        </div>
    );
}
