import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import { allUsersApi } from '../../../../utils/api';
import ExplorerProfile from '../../../../components/explorerProfile';
import DrawerNavbar from '../../../../components/navbar/drawerNavbar';
import TopDownloadRedirect from '../../../../components/topDownloadRedirect';
import LoadingBar from '../../../../components/loadingBar';
import SignInOptionsCard from '../../../../components/signInOptionsCard';
import MiddleContainerHeader from '../../../../components/middleContainerHeader';

export default function Explore() {
    const { showSignInOptions } = useSelector((state) => state).authReducer;

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [users, setUsers] = useState([]);
    let drawerNavbar;
    let signInOptions;

    if (isDrawerOpen) {
        drawerNavbar = <DrawerNavbar click={() => setDrawerOpen(!isDrawerOpen)} />;
    }
    if (showSignInOptions) {
        signInOptions = <SignInOptionsCard style={{ display: 'block' }} />;
    }

    useEffect(async () => {
        document.title = 'Explore Audio Jockeys';
        allUsersApi()
            .then((data) => {
                setUsers(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    return (
        <div className={styles.exploreContainer}>
            <TopDownloadRedirect />
            <MiddleContainerHeader header="Explore" onClick={() => setDrawerOpen(!isDrawerOpen)} />
            {users.length !== 0 ? (
                <div className={styles.exploreContent}>
                    {users.map((data, i) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return <ExplorerProfile key={i} data={data} />;
                    })}
                </div>
            ) : (
                <LoadingBar />
            )}
            {signInOptions}
            {drawerNavbar}
        </div>
    );
}
