import React from 'react';
import styles from './styles.module.css';

export default function LoadingCard() {
    return (
        <div className={styles.loadingCardBackground}>
            <div className={styles.loadingCard}>
                <img
                    alt="logo"
                    src="https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/Web%2Fjustbaat-logo.png?alt=media&token=216f321f-1d5b-4b18-b211-9e46ff8cbf13"
                />
                <span className={styles.progressLoader} />
            </div>
        </div>
    );
}
