import React from 'react';
import { FaGooglePlay } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { setSignInOptionsVisible } from '../../redux/actions/authActions';
import SignInButton from '../signInButton';

export default function SignInOptionsCard(props) {
    const dispatch = useDispatch();

    // eslint-disable-next-line func-names
    window.onclick = function (event) {
        const SignInOptionCard = document.getElementsByClassName(styles.cardBackground);
        try {
            if (event.target === SignInOptionCard[0]) {
                SignInOptionCard[0].style.display = 'none';
                dispatch(setSignInOptionsVisible(false));
            }
            // eslint-disable-next-line no-empty
        } catch (e) {}
    };

    return (
        // eslint-disable-next-line react/destructuring-assignment
        <div className={styles.cardBackground} style={props.style}>
            <div className={styles.signInOptionCard}>
                <img
                    alt="logo"
                    src="https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/Web%2Fjustbaat-logo.png?alt=media&token=216f321f-1d5b-4b18-b211-9e46ff8cbf13"
                />
                <p>For More Download the App Now...</p>
                <SignInButton
                    onClick={() =>
                        window.open(
                            'https://play.google.com/store/apps/details?id=com.justbaat.android',
                            '_blank'
                        )
                    }
                    icon={<FaGooglePlay size={33} />}
                    label_1="GET IT ON"
                    label_2="Google Play"
                />
            </div>
        </div>
    );
}
