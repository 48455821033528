import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import styles from './styles.module.css';
import AxiosApi from '../../../../../services/axios-api';
import { Loading } from '../../../../../components/@embed';
import { timeStamp, useQuery } from '../../../../../utils';
import { roomWidgetShown } from '../../../../../utils/analytics';

// http://localhost:3000/embed/room-card-1?roomId=73Mlw9zvugggrXZY8ExS&appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST
function RoomCard1() {
    const [room, setRoom] = useState(null);
    const query = useQuery();
    const roomId = query.get('roomId');
    const appId = query.get('appId');
    const secret = query.get('secret');
    const partner_id = query.get('partner_id');

    useEffect(() => {
        AxiosApi.post('cloud_function', `/sdk_api/v1/getRoomById`, 'getRoomById', {
            partner_id,
            appId,
            secret,
            roomId
        }).then((res) => {
            setRoom(res.data);
            roomWidgetShown(res.data.id);
        });
    }, []);

    const openPopUp = () => {
        // roomWidgetClicked(room.id);
        window.parent.postMessage(
            { type: 'popup-room', data: room },
            window.location !== window.parent.location
                ? document.referrer || '*'
                : document.location.href
        );
    };

    if (!room)
        return (
            <div className={styles['jb-room-card-1']}>
                <Loading />
            </div>
        );

    return (
        <div className={styles['jb-room-card-1']} onClick={openPopUp}>
            <img className={styles['jb-room-card-1-cover']} src={room?.logoURI} alt="logo" />
            <img
                className={styles['jb-room-card-1-branding']}
                src="/@embed/img/justbaat.svg"
                alt="logo"
            />
            <div className={styles['jb-room-card-1-content']}>
                <Typography component="p" variant="subtitle1">
                    {room?.name}
                </Typography>
                <Stack direction="row" spacing={1}>
                    <img alt="live" src="/@embed/img/live.svg" />
                    <Typography component="span" variant="body2">
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        {timeStamp(room?.modified_date)}
                    </Typography>
                </Stack>
            </div>
            <Button
                variant="contained"
                sx={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '1.5%',
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    '&:hover': {
                        backgroundColor: '#efefef'
                    }
                }}
            >
                Join Now
            </Button>
        </div>
    );
}

export default RoomCard1;
