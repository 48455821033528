import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FaBook, FaHashtag, FaHeadset, FaHome } from 'react-icons/fa';
import { MdPermCameraMic, MdPrivacyTip, MdSettings } from 'react-icons/md';
import { HiDotsCircleHorizontal } from 'react-icons/hi';
import { BsFillPersonFill } from 'react-icons/bs';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { AiTwotoneAudio } from 'react-icons/ai';
import { IoMdContacts } from 'react-icons/io';
import styles from './styles.module.css';
import { logo, logo2 } from '../../../utils/constants';
import firebase from '../../../utils/firebaseClient';

function NavbarPrivate() {
    const auth = firebase.auth();
    const [user] = useAuthState(auth);
    const history = useHistory();
    const [isProfileSwitcherOpen, setProfileSwitcherOpen] = useState(false);
    const [isLogoutOpen, setLogoutOpen] = useState(false);
    const [isMoreOpen, setMoreOpen] = useState(false);
    const userName = user.email.slice(0, user.email.search('@'));
    const iconColor = '#1C9DEA';

    useEffect(() => {
        const DropdownProfileSwitcherCard = document.getElementsByClassName(
            styles.dropdownProfileSwitcherCard
        );
        if (isProfileSwitcherOpen) {
            DropdownProfileSwitcherCard[0].style.display = 'grid';
        } else {
            DropdownProfileSwitcherCard[0].style.display = 'none';
        }
    }, [isProfileSwitcherOpen]);

    useEffect(() => {
        const LogoutCard = document.getElementsByClassName(styles.logoutCardBackground);
        if (isLogoutOpen) {
            LogoutCard[0].style.display = 'grid';
        } else {
            LogoutCard[0].style.display = 'none';
        }
    }, [isLogoutOpen]);

    useEffect(() => {
        const NavbarMoreMenu = document.getElementsByClassName(styles.navbarMoreMenu);
        if (isMoreOpen) {
            NavbarMoreMenu[0].style.display = 'grid';
        } else {
            NavbarMoreMenu[0].style.display = 'none';
        }
    }, [isMoreOpen]);

    window.onclick = function (event) {
        const DropdownProfileSwitcherCard = document.getElementsByClassName(
            styles.dropdownProfileSwitcherCard
        );
        const NavbarMoreMenu = document.getElementsByClassName(styles.navbarMoreMenu);
        try {
            if (event.target !== DropdownProfileSwitcherCard[0]) {
                DropdownProfileSwitcherCard[0].style.display = 'none';
            }
            if (event.target !== NavbarMoreMenu[0]) {
                NavbarMoreMenu[0].style.display = 'none';
            }
            // eslint-disable-next-line no-empty
        } catch (e) {}
    };

    return (
        <nav className={styles.navbar}>
            <Link to="/home">
                <img className={styles.logo} alt="logo" src={logo} height={50} />
            </Link>
            <Link to="/home">
                <img className={styles.logoAlt} alt="logo" src={logo2} height={50} />
            </Link>
            <ul className={styles.navTop}>
                <NavLink activeClassName={styles.active} to="/home">
                    <li aria-label="Home">
                        <FaHome size={25} color={iconColor} />
                        <span>Home</span>
                    </li>
                </NavLink>
                <NavLink activeClassName={styles.active} to="/explore">
                    <li aria-label="Explore">
                        <FaHashtag size={25} color={iconColor} />
                        <span>Explore</span>
                    </li>
                </NavLink>
                <NavLink activeClassName={styles.active} to="/rooms">
                    <li aria-label="Rooms">
                        <MdPermCameraMic size={25} color={iconColor} />
                        <span>Rooms</span>
                    </li>
                </NavLink>
                <NavLink activeClassName={styles.active} to="/jockeys">
                    <li aria-label="Jockeys">
                        <FaHeadset size={25} color={iconColor} />
                        <span>Jockeys</span>
                    </li>
                </NavLink>
                <NavLink activeClassName={styles.active} to="/priyanshu.gupta1312">
                    <li aria-label="Profile">
                        <BsFillPersonFill size={25} color={iconColor} />
                        <span>Profile</span>
                    </li>
                </NavLink>
                <div className={styles.dropdownNavbarMore}>
                    <li onClick={() => setMoreOpen(!isMoreOpen)} aria-label="More">
                        <HiDotsCircleHorizontal size={25} color={iconColor} />
                        <span>More</span>
                    </li>
                    <div className={styles.navbarMoreMenu}>
                        <a href="/about-us">
                            <FaBook size={20} color={iconColor} />
                            <span>About us</span>
                        </a>
                        <a href="/privacy-policy">
                            <MdPrivacyTip size={20} color={iconColor} />
                            <span>Privacy Policy</span>
                        </a>
                        <a href="/">
                            <MdSettings size={20} color={iconColor} />
                            <span>Settings</span>
                        </a>
                    </div>
                </div>
            </ul>
            <ul className={styles.navMid}>
                <li>
                    <AiTwotoneAudio size={25} color={iconColor} />
                    <label>Audio Shot</label>
                </li>
                <li>
                    <IoMdContacts size={25} color={iconColor} />
                    <label>Live Room</label>
                </li>
            </ul>
            <ul className={styles.navMidAlt}>
                <li aria-label="Audio Shot">
                    <AiTwotoneAudio size={25} color={iconColor} />
                </li>
                <li aria-label="Live Room">
                    <IoMdContacts size={25} color={iconColor} />
                </li>
            </ul>
            <div className={styles.dropdownProfileSwitcherCard}>
                <div className={styles.dropdownProfileSwitcherCardProfile}>
                    <img alt="Index Pic" src={user.photoURL} />
                    <div>
                        <span>{user.displayName} </span>
                        <label>{userName}</label>
                    </div>
                </div>
                <div
                    className={styles.dropdownProfileSwitcherCardButton}
                    onClick={() => setLogoutOpen(true)}
                >
                    Logout
                </div>
            </div>
            <div className={styles.navBottom}>
                <div
                    className={styles.navBottomButton}
                    aria-label={userName}
                    onClick={() => setProfileSwitcherOpen(!isProfileSwitcherOpen)}
                >
                    <img alt="Index Pic" src={user.photoURL} />
                    <div>
                        <span>{user.displayName} </span>
                        <label>{userName}</label>
                    </div>
                    <BiDotsHorizontalRounded size={30} color="#1a76f6" />
                </div>
            </div>
            <div className={styles.logoutCardBackground}>
                <div className={styles.logoutCard}>
                    <img
                        alt="logo"
                        src="https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/Web%2Fjustbaat-logo.png?alt=media&token=216f321f-1d5b-4b18-b211-9e46ff8cbf13"
                    />
                    <button onClick={() => auth.signOut().then(() => history.push('/'))}>
                        LOGOUT
                    </button>
                    <button onClick={() => setLogoutOpen(false)}>CANCEL</button>
                </div>
            </div>
        </nav>
    );
}

export default NavbarPrivate;
