import React from 'react';
import { useParams } from 'react-router-dom';
import { PodcastList1, PodcastList2, PodcastPlayer1 } from './podcast';
import { AajtakCard1, RoomCard1, RoomList1 } from './room';
import PodcastPopUpPlayer1 from './podcast/popup-player/player-1';

function EmbedTemplate() {
    // eslint-disable-next-line no-unused-vars
    const { template } = useParams();
    switch (template) {
        case 'podcast-player-1': {
            return <PodcastPlayer1 />;
        }
        case 'podcast-list-template-1': {
            return <PodcastList1 />;
        }
        case 'podcast-list-template-2': {
            return <PodcastList2 />;
        }
        case 'podcast-popup-player-1': {
            return <PodcastPopUpPlayer1 />;
        }
        case 'room-card-1': {
            return <RoomCard1 />;
        }
        case 'aajtak-room-card-1': {
            return <AajtakCard1 />;
        }
        case 'room-list-1': {
            return <RoomList1 />;
        }
        default: {
            return <PodcastPlayer1 />;
        }
    }
}

export default EmbedTemplate;
