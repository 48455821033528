import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.css';

function ContentMenu(props) {
    const { contentMenu } = props;
    const location = useLocation();

    const [active, setActive] = useState(contentMenu && contentMenu[0].link);

    const handleChange = (item) => {
        setActive(item.link);
    };

    return (
        <div className={styles.contentMenu}>
            <nav>
                <p>
                    <a href={`${location.pathname}${location.search}#top`}>CONTENT</a>
                </p>
                <ul>
                    {contentMenu &&
                        contentMenu.map((item) => (
                            <li
                                className={active === item.link ? styles.active : undefined}
                                key={item.link}
                                onClick={() => handleChange(item)}
                            >
                                <a href={`${location.pathname}${location.search}#${item.link}`}>
                                    {item.title}
                                </a>
                            </li>
                        ))}
                </ul>
            </nav>
        </div>
    );
}

export default ContentMenu;
