import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import App from './App';
import store from './redux/store';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <ThemeConfig>
                    <GlobalStyles />
                    <App />
                </ThemeConfig>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
