import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';

export default function TopDownloadRedirect() {
    const [isVisible, setVisibility] = useState(true);
    const constainerRef = useRef(undefined);

    useEffect(() => {
        if (isVisible && window.innerWidth <= 500) constainerRef.current.style.display = 'flex';
        else constainerRef.current.style.display = 'none';
    }, [isVisible]);

    return (
        <div className={styles.topContainer} ref={constainerRef}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button onClick={() => setVisibility(false)} />
            <div>
                <span>Looking for best experience?</span>
                <label>
                    Download and install <b>justbaat</b> for android for free
                </label>
            </div>
            <a
                href="https://play.google.com/store/apps/details?id=com.justbaat.android"
                target="_blank"
                rel="noreferrer"
            >
                Install
            </a>
        </div>
    );
}
