import React from 'react';
import Home from './home';
import Rooms from './rooms';
import Jockeys from './jockeys';
import Profile from './profile';
import Explore from './explore';
import Recommended from './recommended';
// import {useParams} from "react-router-dom";

export default function Feeds(props) {
    // const {feed} = useParams();
    // eslint-disable-next-line react/destructuring-assignment
    switch (props.feed) {
        case 'Home':
            return <Home />;
        case 'Rooms':
            return <Rooms />;
        case 'Jockeys':
            return <Jockeys />;
        case 'Explore':
            return <Explore />;
        case 'Recommended':
            return <Recommended />;
        case 'Profile':
            return <Profile />;
        default:
            return <Home />;
    }
}
