import React, { useState, useEffect } from 'react';
import {
    Stack,
    Alert,
    MenuItem,
    Select,
    Box,
    Button,
    TextField,
    Typography,
    FormHelperText,
    Paper,
    Modal,
    IconButton,
    Snackbar
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import AxiosApiService from '../../services/axios-api';
import LoadingBar from '../../components/loadingBar';

const MiddleContainer = styled(Paper)(({ theme }) => ({
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 10)
}));

const ModalContainer = styled(Paper)(({ theme }) => ({
    boxShadow: 24,
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 1)
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1, 1)
}));

export default function Summary() {
    const [lang, setLanguage] = useState('');
    const [text, setText] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [summary, setSummary] = useState('');
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState('');

    useEffect(() => {
        if (!showSnackBar) setSnackBarMsg('');
    }, [showSnackBar]);

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleText = (event) => {
        setText(event.target.value);
    };

    const copyToClipboard = (txt, title) => {
        if (!navigator.clipboard) {
            setSnackBarMsg('COPY FAILED');
            setShowSnackBar(true);
        } else {
            navigator.clipboard.writeText(txt).then(() => setSnackBarMsg(title));
            setShowSnackBar(true);
        }
    };

    const handleSummary = async () => {
        setSummary('');
        if (!text || !lang) {
            setError(true);
            return;
        }
        const data = {
            content: text,
            lang
        };

        const finalSummary = await AxiosApiService.post(
            'cloud_function',
            '/sdk_api/v1/summarization',
            'direct-summary',
            data
        );

        if (finalSummary.data) {
            const { id } = finalSummary.data.response.data;
            setOpen(true);
            let count = 0;
            const getSummary = setInterval(async () => {
                try {
                    const summaryValue = await AxiosApiService.get(
                        'cloud_function',
                        `/sdk_api/v1/summarization?id=${id}`,
                        'get-summary'
                    );

                    if (summaryValue?.response?.status === 'success') {
                        clearInterval(getSummary);
                        setSummary(summaryValue.response.data);
                    }
                    if (summaryValue?.response?.status === 'fail') {
                        clearInterval(getSummary);
                        setSnackBarMsg('Please Try Again');
                        setShowSnackBar(true);
                        setOpen(false);
                    }
                    if (summaryValue?.response?.status === 'pending') {
                        if (count > 8) {
                            setSnackBarMsg('Please Try Again');
                            setShowSnackBar(true);
                            clearInterval(getSummary);
                            setOpen(false);
                        } else {
                            count += 1;
                        }
                    }
                } catch (err) {
                    setSnackBarMsg('Please Try Again');
                    setShowSnackBar(true);
                    console.log('err: ', err);
                    clearInterval(getSummary);
                }
            }, 8000);
        } else {
            setOpen(false);
            setSnackBarMsg('something wrong');
            setShowSnackBar(true);
        }
    };

    const handleClose = () => {
        if (summary) {
            setOpen(false);
        }
    };

    const getSummaryModelContent = (key, content) => {
        return (
            <Stack direction="column" sx={{ padding: '15px 20px' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1">{key.replaceAll('_', ' ') || ''}</Typography>
                    <IconButton
                        onClick={() => copyToClipboard(content, 'COPIED')}
                        sx={{
                            flexDirection: 'row-reverse',
                            float: 'right',
                            width: 'max-content'
                        }}
                    >
                        <ContentCopy />
                    </IconButton>
                </Stack>
                <Typography variant="body1">{content || ''}</Typography>
            </Stack>
        );
    };

    return (
        <MiddleContainer>
            <Typography variant="h3">Text Summariser</Typography>
            <Stack spacing={2} mt={15} justifyContent="center" alignItems="center" id="something">
                <Stack spacing={2} width="600px">
                    <Typography variant="subtitle1">Add text</Typography>
                    <TextField
                        type="text"
                        fullWidth
                        placeholder="Enter your text here"
                        multiline
                        minRows={5}
                        value={text}
                        onChange={handleText}
                        error={!text && error}
                        id="outlined-error-helper-text"
                        helperText={!text && error && 'Text is required'}
                    />
                </Stack>
                <Stack spacing={2} width="600px">
                    <Typography variant="subtitle1">Select language</Typography>
                    <Select
                        value={lang}
                        onChange={handleChange}
                        fullWidth
                        error={!lang && error}
                        id="outlined-error-helper-lang"
                    >
                        <MenuItem value="HI">Hindi</MenuItem>
                        <MenuItem value="EN">English</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                        {!lang && error && 'Language is required'}{' '}
                    </FormHelperText>
                </Stack>
                <Button
                    variant="contained"
                    sx={{ alignSelf: 'center', mt: 10 }}
                    onClick={handleSummary}
                >
                    Get Summary
                </Button>
            </Stack>
            <Modal open={open} onClose={handleClose} sx={{ display: 'flex' }} alignItems="center">
                <Stack direction="row" sapcing={2} alignItems="center" alignSelf="center" mx={30}>
                    {!summary ? (
                        <LoadingContainer>
                            <LoadingBar />
                            <Typography variant="h3" color="#1C9DEA">
                                Fetching the Summary
                            </Typography>
                        </LoadingContainer>
                    ) : (
                        <ModalContainer>
                            {Array.isArray(summary)
                                ? getSummaryModelContent('', summary.join())
                                : Object.keys(summary).map((key) =>
                                      getSummaryModelContent(key, summary[key])
                                  )}
                        </ModalContainer>
                    )}
                </Stack>
            </Modal>
            <Snackbar
                open={showSnackBar}
                autoHideDuration={6000}
                onClose={() => setShowSnackBar(false)}
            >
                <Alert
                    onClose={() => setShowSnackBar(false)}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
        </MiddleContainer>
    );
}
