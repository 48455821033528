import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import styles from './styles.module.css';
import Footer from '../../components/footer';
import NavbarTop from '../../components/navbar/navbarTop';

export default function PrivacyPolicy() {
    return (
        <div>
            <NavbarTop selected="Privacy Policy" id="top" />
            <div className={styles.privacyContainer}>
                <div className={styles.subNav}>
                    <div className={styles.subNavLeft}>
                        <h1>Privacy Notice</h1>
                        <label>Last updated March 27, 2023</label>
                    </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a className={styles.buttonToTop} href="/privacy-policy.html/#top">
                    <FaArrowUp size={25} color="white" />
                </a>
                <div className={styles.privacyContent}>
                    <div className={styles.content}>
                        <p>
                            This Privacy Policy outlines how our ad network collects, uses, and
                            shares information from users of our platform. We understand the
                            importance of privacy and are committed to protecting the personal
                            information of our users.
                        </p>
                    </div>
                    <div className={styles.content}>
                        <h4>
                            Please read this privacy notice carefully as it will help you understand
                            what we do with the information that we collect.
                        </h4>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>INFORMATION COLLECTED</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                We collect information from users in order to serve personalised
                                ads. This information may include:
                            </div>
                            <div>
                                <li>
                                    <p>IP address</p>
                                </li>
                                <li>
                                    <p>Browser type and version</p>
                                </li>
                                <li>
                                    <p>Operating system</p>
                                </li>
                                <li>
                                    <p>Mobile device information (including device ID)</p>
                                </li>
                                <li>
                                    <p>Demographic information (such as age and gender)</p>
                                </li>
                                <li>
                                    <p>Location data</p>
                                </li>
                                <li>
                                    <p>User interests and behaviour on the platform</p>
                                </li>
                            </div>
                            <div>
                                <b>
                                    We may collect this information directly from users or through
                                    third-party sources.
                                </b>
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>USE OF INFORMATION</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                We use the information collected to serve personalised ads to users.
                                This includes:
                            </div>
                            <div>
                                <li>
                                    <p>
                                        Displaying ads that are relevant to users' interests and
                                        behaviour on the platform
                                    </p>
                                </li>
                                <li>
                                    <p>Measuring the effectiveness of ads</p>
                                </li>
                                <li>
                                    <p>Providing reporting and analytics to advertisers</p>
                                </li>
                                <li>
                                    <p>Improving our platform and services</p>
                                </li>
                            </div>
                            <div>
                                <b>
                                    We may also use the information collected for other purposes
                                    that are consistent with this Privacy Policy.
                                </b>
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>SHARING OF INFORMATION</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                We may share the information collected with third parties for the
                                purposes of serving personalised ads. This may include:
                            </div>
                            <div>
                                <li>
                                    <p>Advertisers and their agents</p>
                                </li>
                                <li>
                                    <p>Third-party ad servers and networks</p>
                                </li>
                                <li>
                                    <p>Measurement and analytics providers</p>
                                </li>
                                <li>
                                    <p>Data management and segmentation providers</p>
                                </li>
                            </div>
                            <div>
                                <b>
                                    We may also share the information collected as required by law
                                    or in response to a legal process
                                </b>
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>YOUTUBE UPLOAD POLICY</div>
                        <ul className={styles.subContent}>
                            <h6 className={styles.subHeader}>
                                We use Youtube API services to connect and upload your content on
                                Youtube
                            </h6>
                            <li>
                                <p>
                                    By connecting and using the Youtube Upload feature, you are
                                    agreeing to be bound by the{' '}
                                    <a href="https://www.youtube.com/t/terms">
                                        YouTube Terms of Service
                                    </a>
                                    .
                                </p>
                            </li>
                            <li>
                                <p>
                                    By connecting and using the Youtube Upload feature, you are
                                    agreeing to{' '}
                                    <a href="https://policies.google.com/privacy">
                                        Google Privacy Policy
                                    </a>
                                    .
                                </p>
                            </li>
                            <li>
                                <p>
                                    You can revoke the API Client's access to users data via the
                                    Google security settings page at{' '}
                                    <a href="https://security.google.com/settings/security/permissions">
                                        https://security.google.com/settings/security/permissions
                                    </a>{' '}
                                    .
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>SECURITY</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                We take reasonable steps to protect the information collected from
                                users. This includes using appropriate physical, technical, and
                                administrative safeguards to prevent unauthorised access, use, or
                                disclosure.
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>CHOICES AND CONTROL</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                Users have certain choices and controls over the information
                                collected. This includes:
                            </div>
                            <div>
                                <li>
                                    <p>
                                        Opting out of personalised ads through the platform's
                                        settings
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Disabling cookies or other tracking technologies in their
                                        browser settings
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Contacting us to request access, correction, or deletion of
                                        their personal information
                                    </p>
                                </li>
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>CHILDREN'S PRIVACY</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                Our platform is not intended for use by children under the age of
                                13. We do not knowingly collect personal information from children
                                under the age of 13. If we become aware that we have inadvertently
                                collected personal information from a child under the age of 13, we
                                will take steps to delete the information as soon as possible.
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>CHANGES TO OUR PRIVACY POLICY</div>
                        <ul className={styles.subContent}>
                            <div className={styles.subHeader}>
                                We may update this Privacy Policy from time to time. Any changes
                                will be posted on our platform and will become effective immediately
                                upon posting.
                            </div>
                        </ul>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentHeader}>CONTACT US</div>
                        <ul className={styles.subContent}>
                            <h6 className={styles.subHeader}>
                                If you have any questions or concerns about this Privacy Policy or
                                our privacy practices, please contact us at {'  '}
                                <a href="mailto:support@justbaat.com">support@justbaat.com</a>.
                            </h6>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer style={{ backgroundColor: 'lightgrey', padding: '15px 0' }} />
        </div>
    );
}
