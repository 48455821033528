import { Link, NavLink } from 'react-router-dom';
import { FaBloggerB, FaGooglePlay, FaHashtag } from 'react-icons/fa';
import { MdPermCameraMic, MdPrivacyTip, MdRecommend } from 'react-icons/md';
import React from 'react';
import styles from './styles.module.css';

// eslint-disable-next-line react/prop-types
function DrawerNavbarPublic({ click }) {
    return (
        <div className={styles.navbarBackground} onClick={click}>
            <nav className={styles.drawerNavbar}>
                <ul>
                    <NavLink activeClassName={styles.active} to="/recommended">
                        <li>
                            <MdRecommend size={25} color="white" />
                            <span>Recommended</span>
                        </li>
                    </NavLink>
                    <NavLink activeClassName={styles.active} to="/explore">
                        <li>
                            <FaHashtag size={25} color="white" />
                            <span>Explore</span>
                        </li>
                    </NavLink>
                    <NavLink activeClassName={styles.active} to="/rooms">
                        <li>
                            <MdPermCameraMic size={25} color="white" />
                            <span>Rooms</span>
                        </li>
                    </NavLink>
                    {/* <Link to="/about-us"> */}
                    {/*    <li><FaBook size={25} color="white"/><span>About us</span></li> */}
                    {/* </Link> */}
                    <Link to="/blog">
                        <li>
                            <FaBloggerB size={25} color="white" />
                            <span>Blog</span>
                        </li>
                    </Link>
                    <Link to="/privacy-policy">
                        <li>
                            <MdPrivacyTip size={25} color="white" />
                            <span>Privacy Policy</span>
                        </li>
                    </Link>
                    {/* <li><FaGoogle size={35} color="white"/><span>SIGN IN WITH Google</span></li> */}
                    <button
                        onClick={() =>
                            window.open(
                                'https://play.google.com/store/apps/details?id=com.justbaat.android',
                                '_blank'
                            )
                        }
                    >
                        <li>
                            <FaGooglePlay size={35} color="white" />
                            <span>GET IT ON Play Store</span>
                        </li>
                    </button>
                </ul>
            </nav>
        </div>
    );
}

export default DrawerNavbarPublic;
