import React, { useEffect, useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import { MdVolumeDown, MdVolumeUp } from 'react-icons/md';
import { LinearProgress } from '@mui/material';
import { logEvent } from '../../../../../utils/firebaseClient';
import styles from './styles.module.css';
import { formatDuration, timeStamp } from '../../../../../utils';
import { Loading } from '../../../../../components/@embed';

function PodcastPopUpPlayer1() {
    const dummyData = {
        weight: 27.585287926590624,
        description: null,
        views: 10,
        userImage:
            'https://lh3.googleusercontent.com/a-/AOh14GgfShbUIUqIKBqi0oX-_H9WL1u1x-Fd-eoVl6II=s96-c',
        replyCount: 0,
        title: 'Apne....',
        categoryId: '6',
        src: 'https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/shots%2f1632840777353_ukpmYVy8RHatdGtPYa0yp4azQaZ2%2fmain.mp3?alt=media',
        lang: 'Hindi',
        userid: 'ukpmYVy8RHatdGtPYa0yp4azQaZ2',
        state: 'PUBLISHED',
        id: '1632840777353_ukpmYVy8RHatdGtPYa0yp4azQaZ2',
        categoryName: 'Poetry',
        userName: 'priti singh',
        promotedDate: {
            _seconds: 1638176724,
            _nanoseconds: 0
        },
        shareUrl: 'https://justbaat.com/link/RD6DXFdyMpm6ffHB8',
        audioOnly: true,
        hashtag: [],
        likes: 3,
        shares: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/shots%2f1632840777353_ukpmYVy8RHatdGtPYa0yp4azQaZ2%2fthumb@256_main_image.jpg?alt=media',
        created_date: {
            _seconds: 1632840779,
            _nanoseconds: 612000000
        }
    };

    const [audioShot, setAudioShot] = useState(dummyData);

    window.addEventListener(
        'message',
        (event) => {
            const { type, data } = event.data;
            if (type === 'load-popup-player') {
                if (data) setAudioShot(data);
                console.log(data);
            }
        },
        false
    );

    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(100);

    const {
        src,
        categoryName,
        title,
        userName,
        image,
        language,
        userid,
        id,
        created_date: createdDate
    } = audioShot;
    const audioRef = useRef(null);
    const intervalRef = useRef();
    const miniPlayer = useRef(null);

    const { duration } = audioRef.current ? audioRef.current : { duration: 100 };
    const iconColor = 'rgba(0,0,0,0.8)';

    const startTimer = () => {
        const eventParams = {
            shotCategory: categoryName,
            shotLanguage: language,
            shotCreatorName: userName,
            shotCreatorId: userid,
            shotId: id
        };
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            const { currentTime, ended } = audioRef.current;
            if (audioRef.current) {
                const position = Number(currentTime.toFixed(1));
                if (position > 10 && position <= 10.2)
                    logEvent('audio_shot_play_10', eventParams).then();
                if (position > 30 && position <= 30.2)
                    logEvent('audio_shot_play_30', eventParams).then();
                if (ended) {
                    clearInterval(intervalRef.current);
                    logEvent('audio_shot_play', eventParams).then();
                } else {
                    setTrackProgress(currentTime);
                }
            }
        }, 100);
    };

    const onScrub = (value) => {
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    };

    const onScrubEnd = () => {
        if (!isPlaying) setIsPlaying(true);
        startTimer();
    };

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play().then();
            startTimer();
        } else {
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        }
    }, [isPlaying]);

    useEffect(() => {
        audioRef.current.volume = volume / 100;
    }, [volume]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const normalise = (value) => (value * 100) / duration;

    return (
        <div ref={miniPlayer} className={styles.audioPlayerContainer}>
            {!audioShot ? (
                <Loading />
            ) : (
                <>
                    <LinearProgress
                        variant="determinate"
                        value={normalise(trackProgress)}
                        sx={{
                            color: iconColor,
                            position: 'absolute',
                            '@media (max-width:500px)': {
                                top: 0,
                                right: 0,
                                left: 0,
                                zIndex: -1
                            }
                        }}
                    />
                    <div className={styles.cover}>
                        <img
                            alt="track artwork"
                            src={image || 'https://picsum.photos/seed/picsum/200/200'}
                        />
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video className={styles.cover} ref={audioRef} src={src} />
                    </div>
                    <div className={styles.infoBar}>
                        <p className={styles.description}>{title || 'Description'}</p>
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        <p className={styles.owner}>{timeStamp(createdDate?._seconds)}</p>
                    </div>
                    <div className={styles.progressBar}>
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            value={trackProgress}
                            min={0}
                            step={1}
                            max={duration || 100}
                            onChange={(e) => onScrub(e.target.value)}
                            onMouseUp={onScrubEnd}
                            onKeyUp={onScrubEnd}
                            sx={{
                                color: iconColor,
                                height: '.2em',
                                '& .MuiSlider-thumb': {
                                    width: '.5em',
                                    height: '.5em',
                                    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                    '&:before': {
                                        boxShadow: '0 .1em .6em 0 rgba(0,0,0,0.4)'
                                    },
                                    '&:hover, &.Mui-focusVisible': {
                                        boxShadow: `0 0 0 .4em rgb(0 0 0 / 16%)`
                                    },
                                    '&.Mui-active': {
                                        width: '1em',
                                        height: '1em'
                                    }
                                },
                                '& .MuiSlider-rail': {
                                    opacity: 0.28
                                }
                            }}
                        />
                        <div className={styles.timeBar}>
                            <label>{formatDuration(trackProgress)}</label>
                            <label>-{formatDuration(duration - trackProgress)}</label>
                        </div>
                    </div>
                    <div className={styles.controls}>
                        <IconButton
                            aria-label={isPlaying ? 'play' : 'pause'}
                            onClick={() => setIsPlaying(!isPlaying)}
                        >
                            {isPlaying ? (
                                <BsPauseFill size="1.5em" color={iconColor} />
                            ) : (
                                <BsPlayFill size="1.5em" color={iconColor} />
                            )}
                        </IconButton>
                    </div>
                    <div className={styles.volumeBar}>
                        <MdVolumeDown size="1.25em" color={iconColor} />
                        <Slider
                            aria-label="Volume"
                            defaultValue={50}
                            value={volume}
                            onChange={(e) => setVolume(e.target.value)}
                            sx={{
                                color: iconColor,
                                '& .MuiSlider-track': {
                                    border: 'none'
                                },
                                '& .MuiSlider-thumb': {
                                    width: '1em',
                                    height: '1em',
                                    backgroundColor: '#fff',
                                    '&:before': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none'
                                    }
                                },
                                '@media (max-width: 1200px)': {
                                    '& .MuiSlider-thumb': {
                                        width: '.7em',
                                        height: '.7em'
                                    }
                                }
                            }}
                        />
                        <MdVolumeUp size="1.25em" color={iconColor} />
                    </div>
                </>
            )}
        </div>
    );
}

export default PodcastPopUpPlayer1;
