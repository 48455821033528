import React from 'react';
import styles from './styles.module.css';

function SignInButton({ onClick, icon, label_1, label_2, style }) {
    return (
        <div className={styles.signInButton} onClick={onClick} style={style}>
            {icon}
            <div>
                <label>{label_1}</label>
                <label>{label_2}</label>
            </div>
        </div>
    );
}

export default SignInButton;
