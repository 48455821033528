import React, { useEffect, useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import { BsPauseFill, BsPlayFill, BsSkipBackwardFill, BsSkipForwardFill } from 'react-icons/bs';
import { MdClose, MdVolumeDown, MdVolumeUp } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { logEvent } from '../../utils/firebaseClient';
import { setAudioShotQueue } from '../../redux/actions/audioShotsActions';
import styles from './styles.module.css';
import { formatDuration } from '../../utils';

export default function MiniAudioPlayer() {
    const dispatch = useDispatch();

    const { audioShotQueue } = useSelector((state) => state).audioShotsReducer;
    const [trackIndex, setTrackIndex] = useState(0);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(100);

    const { src, categoryName, title, userName, image, language, userid, id } =
        audioShotQueue[trackIndex];
    const audioRef = useRef(null);
    const intervalRef = useRef();
    const isReady = useRef(false);
    const miniPlayer = useRef(null);

    const { duration } = audioRef.current ? audioRef.current : { duration: 100 };
    const iconColor = 'rgba(0,0,0,0.8)';

    const startTimer = () => {
        const eventParams = {
            shotCategory: categoryName,
            shotLanguage: language,
            shotCreatorName: userName,
            shotCreatorId: userid,
            shotId: id
        };
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            const { currentTime, ended } = audioRef.current;
            if (audioRef.current) {
                const position = Number(currentTime.toFixed(1));
                if (position > 10 && position <= 10.2)
                    logEvent('audio_shot_play_10', eventParams).then();
                if (position > 30 && position <= 30.2)
                    logEvent('audio_shot_play_30', eventParams).then();
                if (ended) {
                    clearInterval(intervalRef.current);
                    logEvent('audio_shot_play', eventParams).then();
                    // eslint-disable-next-line no-use-before-define
                    toNextTrack();
                } else {
                    setTrackProgress(currentTime);
                }
            }
        }, 100);
    };

    const onScrub = (value) => {
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    };

    const onScrubEnd = () => {
        if (!isPlaying) setIsPlaying(true);
        startTimer();
    };

    const toPrevTrack = () => {
        if (trackIndex - 1 < 0) {
            setTrackIndex(audioShotQueue.length - 1);
        } else {
            setTrackIndex(trackIndex - 1);
        }
    };

    const toNextTrack = () => {
        if (trackIndex < audioShotQueue.length - 1) {
            setTrackIndex(trackIndex + 1);
        } else {
            setTrackIndex(0);
        }
    };

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play().then();
            startTimer();
        } else {
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        }
    }, [isPlaying]);

    useEffect(() => {
        audioRef.current.volume = volume / 100;
    }, [volume]);

    useEffect(() => {
        audioRef.current.pause();
        setTrackProgress(audioRef.current.currentTime);
        if (isReady.current) {
            audioRef.current.play().then();
            setIsPlaying(true);
            startTimer();
        } else {
            isReady.current = true;
        }
    }, [trackIndex]);

    useEffect(() => {
        setIsPlaying(true);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const normalise = (value) => (value * 100) / duration;

    return (
        <div ref={miniPlayer} className={styles.audioPlayerContainer}>
            <IconButton
                onClick={() => dispatch(setAudioShotQueue([]))}
                sx={{
                    position: 'absolute',
                    top: -15,
                    right: -15,
                    backgroundColor: 'white',
                    '@media (max-width:700px)': {
                        position: 'absolute',
                        top: -18,
                        right: 0,
                        backgroundColor: 'rgba(255,255,255,0.5)'
                    }
                }}
            >
                <MdClose />
            </IconButton>
            <LinearProgress
                variant="determinate"
                value={normalise(trackProgress)}
                sx={{
                    color: iconColor,
                    position: 'absolute',
                    '@media (max-width:500px)': {
                        top: 0,
                        right: 0,
                        left: 0,
                        zIndex: -1
                    }
                }}
            />
            <div className={styles.cover}>
                <img
                    alt="track artwork"
                    src={image || 'https://picsum.photos/seed/picsum/200/200'}
                />
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video className={styles.cover} ref={audioRef} src={src} />
            </div>
            <div className={styles.infoBar}>
                <p className={styles.caption}>{categoryName || 'Category'}</p>
                <p className={styles.description}>{title || 'Description'}</p>
                <p className={styles.owner}>{userName || 'Owner Name'}</p>
            </div>
            <div className={styles.progressBar}>
                <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={trackProgress}
                    min={0}
                    step={1}
                    max={duration || 100}
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    sx={{
                        color: iconColor,
                        height: '.2em',
                        '& .MuiSlider-thumb': {
                            width: '.5em',
                            height: '.5em',
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                            '&:before': {
                                boxShadow: '0 .1em .6em 0 rgba(0,0,0,0.4)'
                            },
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0 0 0 .4em rgb(0 0 0 / 16%)`
                            },
                            '&.Mui-active': {
                                width: '1em',
                                height: '1em'
                            }
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.28
                        }
                    }}
                />
                <div className={styles.timeBar}>
                    <label>{formatDuration(trackProgress)}</label>
                    <label>-{formatDuration(duration - trackProgress)}</label>
                </div>
            </div>
            <div className={styles.controls}>
                <IconButton aria-label="previous song" onClick={toPrevTrack}>
                    <BsSkipBackwardFill size="1em" color={iconColor} />
                </IconButton>
                <IconButton
                    aria-label={isPlaying ? 'play' : 'pause'}
                    onClick={() => setIsPlaying(!isPlaying)}
                >
                    {isPlaying ? (
                        <BsPauseFill size="1.5em" color={iconColor} />
                    ) : (
                        <BsPlayFill size="1.5em" color={iconColor} />
                    )}
                </IconButton>
                <IconButton aria-label="next song" onClick={toNextTrack}>
                    <BsSkipForwardFill size="1em" color={iconColor} />
                </IconButton>
            </div>
            <div className={styles.volumeBar}>
                <MdVolumeDown size="1.25em" color={iconColor} />
                <Slider
                    aria-label="Volume"
                    defaultValue={50}
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                    sx={{
                        color: iconColor,
                        '& .MuiSlider-track': {
                            border: 'none'
                        },
                        '& .MuiSlider-thumb': {
                            width: '1em',
                            height: '1em',
                            backgroundColor: '#fff',
                            '&:before': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.4)'
                            },
                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                boxShadow: 'none'
                            }
                        },
                        '@media (max-width: 1200px)': {
                            '& .MuiSlider-thumb': {
                                width: '.7em',
                                height: '.7em'
                            }
                        }
                    }}
                />
                <MdVolumeUp size="1.25em" color={iconColor} />
            </div>
        </div>
    );
}
