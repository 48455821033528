import React, { useState } from 'react';
import { Box } from '@mui/material';
import SideBar from '../../components/documentation/sidebar';
// import GettingStarted from './getting_started';
import DocAppBar from '../../components/documentation/appbar';
import { useQuery } from '../../utils';
import ContentMenu from '../../components/documentation/contentMenu';
// import {
//     RoomsCardsImplementation,
//     RoomsFloatingButtonImplementation,
//     RoomsFullscreenImplementation,
//     RoomsWidgetImplementation
// } from './rooms';
import {
    // PodcastListImplementation,
    // InstoryWidgetImplementation,
    MetaHumanWidgetImplementation
} from './podcast';

function Documentation() {
    const query = useQuery();
    const [contentMenu, setContentMenu] = useState([
        {
            link: 'preview',
            title: 'Preview'
        },
        {
            link: 'html-css-js',
            title: 'HTML/CSS/JS'
        }
        // {
        //     link: 'react-js',
        //     title: 'React Js'
        // },
        // {
        //     link: 'wordpress',
        //     title: 'Wordpress'
        // },
        // {
        //     link: 'wix',
        //     title: 'Wix'
        // }
    ]);

    const content = query.get('content') || 'metaHuman-widget-implementation';

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const drawerWidth = 240;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex', marginTop: 5 }}>
            <DocAppBar {...{ drawerWidth, handleDrawerToggle }} />
            <SideBar
                {...{
                    content,
                    handleDrawerToggle,
                    mobileOpen,
                    drawerWidth
                }}
            />
            <MetaHumanWidgetImplementation {...{ setContentMenu }} />
            {/* <ShowContent {...{ content, setContentMenu }} /> */}
            <ContentMenu contentMenu={contentMenu} />
        </Box>
    );
}

// function ShowContent({ content, setContentMenu }) {
//     switch (content) {
//         case 'getting-started':
//             return <GettingStarted {...{ setContentMenu }} />;
//         case 'fullscreen-implementation':
//             return <RoomsFullscreenImplementation {...{ setContentMenu }} />;
//         case 'floating-button-implementation':
//             return <RoomsFloatingButtonImplementation {...{ setContentMenu }} />;
//         case 'cards-implementation':
//             return <RoomsCardsImplementation {...{ setContentMenu }} />;
//         case 'widget-implementation':
//             return <RoomsWidgetImplementation {...{ setContentMenu }} />;
//         case 'podcast-list-implementation':
//             return <PodcastListImplementation {...{ setContentMenu }} />;
//         case 'instory-widget-implementation':
//             return <InstoryWidgetImplementation {...{ setContentMenu }} />;
//         case 'metaHuman-widget-implementation':
//             return <MetaHumanWidgetImplementation {...{ setContentMenu }} />;
//         // case 'podcast-cards-implementation':
//         //     return <PodcastCardsImplementation {...{ setContentMenu }} />;
//         default:
//             return <MetaHumanWidgetImplementation {...{ setContentMenu }} />;
//     }
// }

export default Documentation;
