import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import Feeds from './feeds';
import Navbar from '../../components/navbar/navbarSide';
import MiniAudioPlayer from '../../components/miniAudioPlayer';

export default function Main(props) {
    const { audioShotQueue } = useSelector((state) => state).audioShotsReducer;
    let miniPlayer;

    if (audioShotQueue.length > 0) {
        miniPlayer = <MiniAudioPlayer />;
    }

    return (
        <div className={styles.mainContainer}>
            <Navbar />
            <div className={styles.middleContainer}>
                {/* eslint-disable-next-line react/destructuring-assignment */}
                <Feeds feed={props.feed} profile={props.profile} />
            </div>
            {miniPlayer}
        </div>
    );
}
