import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import Footer from '../../components/footer';
import NavbarTop from '../../components/navbar/navbarTop';

export default function AjPayoutsPage() {
    return (
        <div>
            <NavbarTop selected="Audio Jockey Payouts">
                {/* <Link to="/about-us"><h2>About Us</h2></Link> */}
                <Link to="/privacy-policy">
                    <h2>Privacy Policy</h2>
                </Link>
            </NavbarTop>
            <div className={styles.ajPayoutsContainer}>
                <div className={styles.contentHeader}>
                    <h2>
                        Get Followers &gt;&gt; Talk to people daily
                        <h1>GET PAID</h1>
                        <h5>for Talking, Lets Justbaat</h5>
                    </h2>
                </div>
                <div className={styles.ajPayoutsContent}>
                    <div className={styles.ajPayoutsSlabs}>
                        <h2>Justbaat Audio Jockey Slabs for September – October </h2>
                        <p>
                            Top AUDIO JOCKEYs will also be offered company ESOPs, once they cross
                            ESOPs, once they cross 2000 followers.
                        </p>
                        <h4>Verified Audio Jockey payout Slabs</h4>
                        <table>
                            <tr>
                                <th>Number of Followers</th>
                                <th>Per user / Show payout</th>
                            </tr>
                            <tr>
                                <td>2001+</td>
                                <td>30</td>
                            </tr>
                            <tr>
                                <td>1001 – 2000</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>501 – 1000</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>301-500</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>150 – 300</td>
                                <td>5</td>
                            </tr>
                        </table>
                        <span>**Slabs will be revised from November</span>
                        <h4>Non - Verified Audio Jockey payout Slabs</h4>
                        <table>
                            <tr>
                                <th>Number of Followers</th>
                                <th>Per user / Show payout</th>
                            </tr>
                            <tr>
                                <td>2001+</td>
                                <td>30</td>
                            </tr>
                            <tr>
                                <td>1001 – 2000</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>501 – 1000</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>301-500</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>150 – 300</td>
                                <td>3</td>
                            </tr>
                        </table>
                        <span>**Slabs will be revised from November</span>
                        <ul>
                            <li>
                                Payout will be based on the number of users joined per session and
                                the slab user is in
                            </li>
                            <li>
                                User should spend at least 1 min inside the room to be counted as
                                successful user and for the audio jockey to become eligible for his
                                payout
                            </li>
                            <li>
                                The money will be transferred to the Paytm accounts of all the
                                hosts, once a week
                            </li>
                            <li>
                                Daily report of earnings will be published by the Justbaat team to
                                all Room hosts
                            </li>
                        </ul>
                    </div>
                    <div className={styles.ajPayoutsExample}>
                        <h2>Example</h2>
                        <p>
                            If 20 users joined in a show for more than a minute and the creator
                            follower slab is 1000+, then the payout will be:
                        </p>
                        <label>20*20 = 400Rs per session</label>
                        <span>
                            If this creator is doing a room for 30 days in a month then 30*400 =
                            12000 Rs a month
                        </span>
                        <i>
                            For queries please follow our Instagram page or write to
                            karishma.y@justbaat.com
                        </i>
                    </div>
                </div>
            </div>
            <Footer style={{ backgroundColor: 'lightgrey', padding: '15px 0' }} />
        </div>
    );
}
