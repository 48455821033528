import React, { useEffect, useRef, useState } from 'react';
import { FaRegPauseCircle, FaRegPlayCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@mui/material';
import { setAudioShotQueue, setSelectedAudioShot } from '../../redux/actions/audioShotsActions';
import styles from './styles.module.css';
import { logEvent } from '../../utils/firebaseClient';

function CircularPlayButton({ data, audioRef, isPage }) {
    const { selectedAudioShot, audioShotQueue } = useSelector((state) => state).audioShotsReducer;
    const dispatch = useDispatch();

    const [trackProgress, setTrackProgress] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [open, setOpen] = React.useState(false);

    const intervalRef = useRef(0);
    const progressRef = useRef(null);

    const playAudio = (e) => {
        e.stopPropagation();
        setIsPaused(!isPaused);
        const eventParams = {
            shotCategory: data.categoryName,
            shotLanguage: data.language,
            shotCreatorName: data.userName,
            shotCreatorId: data.userid,
            shotId: data.id
        };
        if (selectedAudioShot.current && selectedAudioShot !== audioRef) {
            audioShotQueue.push(data);
            dispatch(setAudioShotQueue(audioShotQueue));
            // alert("Added to Queue...")
            setOpen(true);
            selectedAudioShot.current.pause();
            selectedAudioShot.current.currentTime = 0;
            // selectedAudioShot.current.onpause = () => {
            //     setIsPaused(!selectedAudioShot.current.paused);
            // };
        }
        if (isPaused) {
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        } else {
            dispatch(setSelectedAudioShot(audioRef));
            if (isPage) {
                audioRef.current.play().then();
                clearInterval(intervalRef.current);
                intervalRef.current = setInterval(() => {
                    try {
                        const { currentTime, duration, ended } = audioRef.current;
                        const position = Number(currentTime.toFixed(1));
                        if (position > 10 && position <= 10.2)
                            logEvent('audio_shot_play_10', eventParams).then();
                        if (position > 30 && position <= 30.2)
                            logEvent('audio_shot_play_30', eventParams).then();
                        if (ended) {
                            setIsPaused(false);
                            clearInterval(intervalRef.current);
                            logEvent('audio_shot_play', eventParams).then();
                            setTrackProgress(duration);
                        } else setTrackProgress(currentTime);
                    } catch (err) {
                        clearInterval(intervalRef.current);
                    }
                }, 250);
            }
            // if(!isPage){
            //     audioRef.current.volume = 0;
            // }
        }
    };

    useEffect(() => {
        try {
            const { duration } = audioRef.current;
            const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
            progressRef.current.style.setProperty(`--progress`, `${currentPercentage}`);
        } catch (err) {
            console.error(err);
        }
    }, [trackProgress]);

    useEffect(() => {
        dispatch(setSelectedAudioShot(audioRef));
        return () => clearInterval(intervalRef.current);
    }, []);

    // const normalise = (value) => (value * 100) / (audioRef.current && audioRef.current.duration);

    return (
        <button className={styles.circularPlayButton} onClick={(e) => playAudio(e)}>
            <div ref={progressRef} className={styles.RadialProgress}>
                {isPaused && !audioRef.current.paused ? (
                    <FaRegPauseCircle color="#1a76f6" />
                ) : (
                    <FaRegPlayCircle color="#1a76f6" />
                )}
            </div>
            {/* <div className={styles.circularProgressButton}> */}
            {/*    {(isPaused && !audioRef.current.paused) ? */}
            {/*        <FaPauseCircle color={"#1C9DEA"}/> : */}
            {/*        <FaPlayCircle color={"#1C9DEA"}/>} */}
            {/*    <CircularProgress */}
            {/*        sx={{ */}
            {/*            position: "absolute", */}
            {/*            left: 0, */}
            {/*            zIndex: 1, */}
            {/*            color: "#1a76f6" */}
            {/*        }} */}
            {/*        variant="determinate" */}
            {/*        thickness={5} */}
            {/*        value={normalise(trackProgress)} /> */}
            {/* </div> */}
            {!isPage && (
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={() => setOpen(false)}
                    message={`Shot by ${data.userName} Added to Queue...`}
                />
            )}
        </button>
    );
}

export default CircularPlayButton;
