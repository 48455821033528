import React from 'react';
import styles from './styles.module.css';

export default function LoadingBar() {
    return (
        <div>
            <div className={styles.progressLoader}>
                <div className={styles.spinner}>
                    <div className={styles.spinner_item} />
                    <div className={styles.spinner_item} />
                    <div className={styles.spinner_item} />
                    <div className={styles.spinner_item} />
                    <div className={styles.spinner_item} />
                </div>
            </div>
        </div>
    );
}
