import { combineReducers } from 'redux';
import { audioShotsReducer } from './audioShotsReducer';
import { authReducer } from './authReducer';

const reducers = combineReducers({
    audioShotsReducer,
    authReducer
});

export default reducers;
