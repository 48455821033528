import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import PrivacyPolicy from './pages/privacy_policy';
import Main from './pages/main';
import LoadingCard from './components/loadingCard';
import AjPayoutsPage from './pages/aj_payouts';
import AudioShotPage from './pages/audioShotPage';
import Terms_Conditions from './pages/terms&conditions';
import firebase from './utils/firebaseClient';
import { setIsLoggedIn } from './redux/actions/authActions';
import Room from './pages/room';
import Blog from './pages/blog';
import Login from './pages';
import Documentation from './pages/documentation';
import EmbedTemplate from './pages/@embed';
import AudioShotsDemo from './pages/documentation/demo/audioshots';
import RoomsDemo from './pages/documentation/demo/room';
import JioAd from './components/Adss/JioAd';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Team from './pages/Team';
import Summary from './pages/summarySvc';
import UrlMeta from './pages/UrlmetaData';
import AdxPage from './pages/adxPage';

const auth = firebase.auth();

export default function App() {
    const [user, loading] = useAuthState(auth);
    const dispatch = useDispatch();

    if (user) dispatch(setIsLoggedIn(true));
    else dispatch(setIsLoggedIn(false));

    return (
        <Router>
            <Switch>
                <Route path="/privacy-policy.html" exact component={PrivacyPolicy} />
                <Route path="/aj-payouts" exact component={AjPayoutsPage} />
                <Route path="/terms&conditions" exact component={Terms_Conditions} />
                <Route path="/blog" exact component={Blog} />
                <Route path="/room" exact component={Room} />
                <Route path="/documentation/rooms-demo" exact component={RoomsDemo} />
                <Route path="/documentation/audioshots-demo" exact component={AudioShotsDemo} />
                <Route path="/documentation" exact component={Documentation} />
                <Route path="/home" exact component={Login} />
                <Route path="/Pricing" exact component={Pricing} />
                <Route path="/Contact" exact component={Contact} />
                <Route path="/Team" exact component={Team} />

                {/* <Route path="/test" exact component={Test}/> */}
                <Route path="/explore" exact>
                    {loading ? <LoadingCard /> : <Main feed="Explore" />}
                </Route>
                <Route path="/recommended" exact>
                    {loading ? <LoadingCard /> : <Main feed="Recommended" />}
                </Route>
                <Route path="/rooms" exact>
                    {loading ? <LoadingCard /> : <Main feed="Rooms" />}
                </Route>
                <Route path="/app/r/:roomId" exact>
                    {loading ? <LoadingCard /> : <Main feed="Rooms" />}
                </Route>
                {/* <Route path="/jockeys" exact>
                    {user ? <Main feed="Jockeys"/> : loading ? <LoadingCard/> : <Redirect to="/"/>}
                </Route> */}
                <Route path="/embed/:template" exact component={EmbedTemplate} />
                <Route path="/app/s/:shotOwnerName/audio-shots/:shotId">
                    <AudioShotPage />
                </Route>
                <Route path="/app/p/:userId">
                    {loading ? <LoadingCard /> : <Main feed="Profile" />}
                </Route>
                <Route path="/" exact component={Login} />
                <Route path="/summary" component={Summary} />
                <Route path="/urlmeta" component={UrlMeta} />
                <Route path="/adx" component={AdxPage} />

                <Route path="/add" exact component={JioAd} />
                
            </Switch>
        </Router>
    );
}
