import React, { useState, useEffect } from 'react';
import {
    Alert,
    Paper,
    Typography,
    Stack,
    TextField,
    Button,
    Box,
    IconButton,
    Snackbar,
    Hidden,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ContentCopy } from '@mui/icons-material';
import axios from 'axios';
import LoadingBar from '../../components/loadingBar';

const MiddleContainer = styled(Paper)(({ theme }) => ({
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 10)
}));


export default function UrlMeta() {
    const [url_input, setUrl] = useState('');
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMsg, setSnackBarMsg] = useState('');
    const [result, setResult] = useState('');
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        if (!showSnackBar) setSnackBarMsg('');
    }, [showSnackBar]);

    const handleChange = (event) => {
        setUrl(event.target.value);
    };


    const handleOpen = async () => {
        if (!url_input) {
            setSnackBarMsg("Please enter a URL");
            setShowSnackBar(true);
            return;
        }

        

        setLoading(true);
        try {
            const validUrl = new URL(url_input);
            const response = await axios.get("https://asia-south1-justbaat-dev.cloudfunctions.net/sdk_api/v1/scrape-website", {
                params: {
                    url: validUrl.href,
                },
            });

            setResult(response.data.data.websiteContent.content);
            if (!response.data.data.websiteContent.content) {
                setSnackBarMsg("no content available");
                setShowSnackBar(true);
            }
        } catch (error) {
            console.error(error);
            setSnackBarMsg("Please enter a valid Url");
            setShowSnackBar(true);
        } finally {
            setLoading(false);
        }
    };




    const copyToClipboard = (txt, title) => {
        if (!navigator.clipboard) {
            setSnackBarMsg('COPY FAILED');
            setShowSnackBar(true);
        } else {
            navigator.clipboard.writeText(txt).then(() => setSnackBarMsg(title));
            setShowSnackBar(true);
        }
    };

    return (
        <MiddleContainer>
            <Typography variant="h3">Url MetaData</Typography>
            <Stack spacing={4} mt={10} justifyContent="center" alignItems="center">
                <Stack spacing={1} width="600px">
                    <Typography variant="subtitle1">URL</Typography>
                    <TextField
                        required
                        id="filled-required"
                        label="Required"
                        value={url_input}
                        onChange={handleChange}
                    />
                </Stack>
                {isLoading ? (
                    <LoadingBar />
                ) : (
                    <Button variant="contained" sx={{ alignSelf: 'center', mt: 10 }} onClick={handleOpen}>
                        Get MetaData
                    </Button>
                )}
            </Stack>
            {result ? (
                <Box component="span" sx={{ p: 2, border: '3px dashed black', mt: 10 }}>
                    {result && (
                        <Stack direction="row" sapcing={2} alignItems="center" alignSelf="center" mx={30}>
                            <Typography
                                variant="subtitle1"
                                sx={{ padding: '16px 32px 32px 32px' }}
                            >
                                {result}
                            </Typography>
                            <IconButton
                                onClick={() => copyToClipboard(result, 'COPIED')}
                                sx={{
                                    flexDirection: 'row-reverse',
                                    alignSelf: 'end',
                                    width: 'max-content'
                                }}
                            >
                                <ContentCopy />
                            </IconButton>
                        </Stack>
                    )}
                </Box>
            ) : (
                <Hidden />
            )}
            <Snackbar
                open={showSnackBar}
                autoHideDuration={6000}
                onClose={() => setShowSnackBar(false)}
            >
                <Alert
                    onClose={() => setShowSnackBar(false)}
                    severity="info"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarMsg}
                </Alert>
            </Snackbar>
        </MiddleContainer>
    );
}