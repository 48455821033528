import React from 'react';
import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { IoMenu } from 'react-icons/io5';
import { isDebug } from '../../../utils/constants';

function DocAppBar(props) {
    const { drawerWidth, handleDrawerToggle } = props;

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
                boxShadow: 'none',
                // backdropFilter: 'blur(6px)',
                // WebkitBackdropFilter: 'blur(6px)',
                background: 'transparent',
                backgroundColor: { xs: '#fff', sm: 'transparent' }
            }}
            elevation={0}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                        mr: 2,
                        display: { md: 'none' },
                        color: 'rgb(28, 157, 234)'
                    }}
                >
                    <IoMenu />
                </IconButton>
                <Box sx={{ width: '90%', marginTop: '48px' }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <Button
                        variant="contained"
                        component="a"
                        href={
                            isDebug
                                ? 'https://sdk-admin.web.app/login'
                                : 'https://console.justbaat.com/login'
                        }
                        target="_blank"
                        style={{ borderRadius: 18 }}
                    >
                        LOGIN
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

export default DocAppBar;
