import React from 'react';
import { FaArrowUp } from 'react-icons/fa';
import styles from './styles.module.css';

export default function FeedEnd({ text }) {
    return (
        <div className={styles.container}>
            <span>{text}</span>
            <p>----x-x-x-x----</p>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a href="#top">
                <span>
                    <FaArrowUp size="0.75em" />
                </span>
            </a>
        </div>
    );
}
