import React, { useEffect } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from '../styles.module.css';
import { useQuery } from '../../../utils';
import DisplayCode from '../../../components/documentation/displayCode';
import { isDebug } from '../../../utils/constants';

function createData(parameter, meaning, sampleValues, Field, FieldType, Value) {
    return { parameter, meaning, sampleValues, Field, FieldType, Value };
}

function Template3Implementation({ setContentMenu }) {
    // const [value, setValue] = React.useState(0);
    const value = 0;
    const query = useQuery();

    const config = {};

    useEffect(() => {
        setContentMenu([
            {
                link: 'preview',
                title: 'Preview'
            },
            {
                link: 'html-css-js',
                title: 'HTML/CSS/JS'
            },
            {
                link: 'meta-human-widget-integration',
                title: 'Content Generation'
            }
        ]);
    }, []);

    config.appId = query.get('appId') || '<---appId--->';
    config.secret = query.get('secret') || '<---secret--->';
    config.partner_id = query.get('partner_id') || '<---partner_id--->';
    config.baseUrl = isDebug ? 'https://audiosdk.web.app' : 'https://audio.justbaat.com';
    config.iframeBaseUrl = isDebug ? 'https://justbaat-debug.web.app' : 'https://justbaat.com';
    config.asyncUrl = 'https://justbaat.org/jb-widget.min.js';

    return (
        <div className={styles.main_container} id="top">
            <h1>MetaHuman Widget</h1>
            <p>This widget shows live MetaHuman.</p>
            <Box sx={{ paddingY: 2 }}>
                <TabPanel value={value} config={config} index={0}>
                    podcast-list-template-1
                </TabPanel>
            </Box>
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index, config, ...other } = props;
    const { appId, secret, partner_id, asyncUrl } = config;

    const asyncHead = ` <div
    class="main-img"
    id="jb-meta-btn-widget"
    style="position: relative"
>
    <img />
</div>`;

    const initConfig =
        `        (function (w, d, s, o, f, js, fjs) {` +
        '\n' +
        `            w[o] =` +
        '\n' +
        `                w[o] ||` +
        '\n' +
        `                function () {` +
        '\n' +
        `                    (w[o].q = w[o].q || []).push(arguments);` +
        '\n' +
        `                };` +
        '\n' +
        `            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);` +
        '\n' +
        `            js.id = o;` +
        '\n' +
        `            js.src = f;` +
        '\n' +
        `            js.async = 1;` +
        '\n' +
        `            fjs.parentNode.insertBefore(js, fjs);` +
        '\n' +
        `        })(window, document, 'script', 'jbWidget', '${asyncUrl}');` +
        '\n' +
        `        jbWidget('init', {` +
        '\n' +
        `            appId: "${appId}",` +
        '\n' +
        `            secret: "${secret}",` +
        '\n' +
        `            partner_id: "${partner_id}",` +
        '\n' +
        `            render: [` +
        '\n' +
        `                {` +
        '\n' +
        `                    wid: 'META_BTN_WIDGET',` +
        '\n' +
        `                    tgtEl: 'jb-meta-btn-widget',` +
        '\n' +
        `                    uiConfig: {` +
        '\n' +
        `                        width: '100%',` +
        '\n' +
        `                        inLinePlayBtn: {` +
        '\n' +
        `                        position: 'CENTER',` +
        '\n' +
        `                        bgColor: 'red',` +
        '\n' +
        `                        shape: 'circle'` +
        '\n' +
        `                        },` +
        '\n' +
        `                        stickyPlayer: {` +
        '\n' +
        `                           mobile: {` +
        '\n' +
        `                              width: 125,` +
        '\n' +
        `                              horizontalPosition: 'left',` +
        '\n' +
        `                              horizontalPositionPx: 5` +
        '\n' +
        `                            },` +
        '\n' +
        `                           desktop: {` +
        '\n' +
        `                              width: 225,` +
        '\n' +
        `                              horizontalPosition: 'left',` +
        '\n' +
        `                              horizontalPositionPx: 10` +
        '\n' +
        `                           }` +
        '\n' +
        `                        }` +
        '\n' +
        `                    },` +
        '\n' +
        `                    widgetConfig: {` +
        '\n' +
        `                        ads: {` +
        '\n' +
        `                        enable: true` +
        '\n' +
        `                        },` +
        '\n' +
        `                        rid: '<---Unique article id--->',` +
        '\n' +
        `                        playerInline: true,` +
        '\n' +
        `                        inlineText: '<---Some Relavent Text--->',` +
        '\n' +
        `                        startPlayerOnLoad: true` +
        '\n' +
        `                        startPlayerOnLoadDelay: 0,` +
        '\n' +
        `                        stickyPlayer: 'STICKY_AT_BOTTOM',` +
        '\n' +
        `                        stickyPlayerPosition: 10,` +
        '\n' +
        `                        stickyPlayerPositionMobile: 5` +
        '\n' +
        `                    }` +
        '\n' +
        `                }` +
        '\n' +
        `            ]` +
        '\n' +
        `        });`;

    const asyncBody = `    <script>\n${initConfig}\n    </script>`;

    const requestUrl = `
        Type : 𝗣𝗢𝗦𝗧
        Url  : ${
            isDebug
                ? `https://asia-south1-justbaat-debug.cloudfunctions.net/sdk_api/v1/text-to-video`
                : 'https://asia-south1-justbaat-dev.cloudfunctions.net/sdk_api/v1/text-to-video'
        }
        `;

    const response = `
        {
            "status": "success",
            "id": "string",
            "rid": "string"
           }
        `;

    const headers = [
        createData('authorization', 'string', 'Basic <--auth_token-->'),
        createData('content-type', 'string', 'application/json')
    ];

    const headersobject = `  headers: {
        'content-type': 'application/json',
        'authorization': Basic <--auth_token-->
      },`;
    const request = [
        createData('rid', 'string', 'Unique id of the content / post'),
        createData('title', 'string', 'Title of the content / post'),
        createData('template', 'string', 'Theme for the article / post'),
        createData('anchor', 'string', 'Anchor for the article / post'),
        createData('sections', 'Array<object>', 'Section wise text and image'),
        createData('images', 'Array<string>', 'Preview / main image'),
        createData('content', 'string', 'Content / post information'),
        createData('category', 'Array<string>', 'Categories of content / post'),
        createData('language', 'string', 'Language of content / post'),
        createData('articleUrl', 'string', 'URL of the content / post'),
        createData('subCategory', 'Array<string>', 'Division of category'),
        createData('tags', 'Array<string>', 'Appropriate label as per the content / post'),
        createData('resourceType', 'string', 'standalone')
    ];

    const requestobject = `  { 
               
        "title": "Title of the content",

        "images": [ "<--image-->" ],

        "content": "content information",

        "template": "template information",

        "anchor": "anchor for content",

        "sections": [
            { "text": "The section one text should be placed here", 
              "asset": ["section two image url should be placed here"]
            },
            { "text": "The section two text should be placed here",
              "asset": ["section two image url should be placed here"]
            }
            ],

        "category": [ "category of content" ],
        
        "language": "language of content",

        "articleUrl":  "https://justbaat.com/",

        "subCategory":  [ "division of category" ], // optional

        "tags": [ "violence" ],   // optional
         
        "resourceType": "standalone"
        }'
        
    `;

    const sampleRequest = `
    const axios = require("axios");

const options = {
  method: 'POST',
  url: 
  ${
      isDebug
          ? `https://asia-south1-justbaat-debug.cloudfunctions.net/sdk_api/v1/text-to-video`
          : 'https://asia-south1-justbaat-dev.cloudfunctions.net/sdk_api/v1/text-to-video'
  } ,
  headers: {
    'content-type': 'application/json',
    'authorization': Basic <--auth_token-->,
  },
  data: '{
     "title": "<--Title of the content-->"
     "images": ["<--images-->"],
     "content": "<--content information-->",
     "template": "<--template information-->",
     "anchor": "<--anchor information-->",
     "category": ["<--category of content-->"],
     "language": "<--language of content-->",
     "articleUrl": "<--https://justbaat.com/-->",
     "subCategory": ["<--division of category-->"],
     "tags": "<--violence-->",
     "resourceType":"standalone",
     "rid":"123456789"

    }'
};

axios.request(options).then(function (response) {
	console.log(response.data);
}).catch(function (error) {
	console.error(error);
});
    `;

    const sampleMultiImageRequest = `
    const axios = require("axios");

const options = {
  method: 'POST',
  url: 
  ${
      isDebug
          ? `https://asia-south1-justbaat-debug.cloudfunctions.net/sdk_api/v1/text-to-video`
          : 'https://asia-south1-justbaat-dev.cloudfunctions.net/sdk_api/v1/text-to-video'
  } ,
  headers: {
    'content-type': 'application/json',
    'authorization': Basic <--auth_token-->,
  },
  data: '{
     "title": "<--Title of the content-->"
     "sections": [
        { "text": "The section one text should be placed here", 
          "asset": ["section two image url should be placed here"]
        },
        { "text": "The section two text should be placed here",
          "asset": ["section two image url should be placed here"]
        }
        ],
     "template": "<--template information-->",
     "anchor": "<--anchor information-->",
     "category": ["<--category of content-->"],
     "language": "<--language of content-->",
     "articleUrl": "<--https://justbaat.com/-->",
     "subCategory": ["<--division of category-->"],
     "tags": "<--violence-->",
     "resourceType":"standalone",
     "rid":"123456789"

    }'
};

axios.request(options).then(function (response) {
	console.log(response.data);
}).catch(function (error) {
	console.error(error);
});
    `;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && (
                <Stack spacing={3} sx={{ p: { sm: 4, xs: 1 } }}>
                    <Stack alignItems="center" id="preview">
                        <div>
                            <img
                                alt="card"
                                height="100%"
                                style={{ maxHeight: '400px' }}
                                src="https://firebasestorage.googleapis.com/v0/b/justbaat-debug.appspot.com/o/image%2Fslide1.png?alt=media&token=e1a1c692-7d16-4fcc-8ac1-926100dcfc43"
                            />
                        </div>
                    </Stack>
                    <Stack spacing={5}>
                        <Stack spacing={3} id="html-css-js">
                            <Stack spacing={1}>
                                <h3>HTML / CSS / JS</h3>
                                <p>how to embed our widget on a html-css-js website.</p>
                            </Stack>

                            <Stack spacing={1}>
                                <DisplayCode children={asyncHead} />
                                <p>
                                    Place the above code at the place where you want to render the
                                    widget in
                                    {'<body>'} tag
                                </p>
                            </Stack>
                            <Stack spacing={1}>
                                <DisplayCode children={asyncBody} />
                                <p className={styles.caption}>
                                    *If Seeing {'<---<key>--->'} in place of your own keys please
                                    login to our console and come-back after creating a room. Refer
                                    to{' '}
                                    <Link to="/documentation">
                                        <b>getting-started</b>
                                    </Link>
                                    *
                                </p>
                                <ol>
                                    <li>Place the above code in {'<body>'} tag of your code.</li>
                                    <b>( OPTIONAL )</b>
                                    <li>
                                        Replace {'<---categories--->'} with <b>category</b> in{' '}
                                        <b>array</b>.
                                        <br />
                                        Eg: <b>['Cricket', 'Health', 'Lifestyle']</b>.
                                    </li>
                                    <li>
                                        Replace {'<---image--->'} with <b>relatable image</b>
                                        <br />
                                        Eg:{' '}
                                        <b>
                                            https://firebasestorage.googleapis.com/v0/b/justbaat-debug.appspot.com/o/image%2FmetaHuman.jpeg?alt=media&token=36cc0695-b2ea-4284-8e34-86bd2cb8598a
                                        </b>
                                        .
                                    </li>
                                    <li>
                                        Replace {'<---Some Relevant Text--->'} with{' '}
                                        <b>text suitable with the article</b>
                                        <br />
                                        Eg:
                                        <b>
                                            inlineText: 'जयशंकर और राजनाथ के सामने अमेरिकी विदेश
                                            मंत्री की असहज करने वाली टिप्पणी'
                                        </b>
                                        .
                                    </li>
                                    <li>startPlayerOnLoad: true</li>
                                    <li>startPlayerOnLoadDelay: 0</li>
                                    <li>
                                        stickyPlayer: 'STICKY_AT_TOP' <br />
                                        <b>
                                            possible values: //STICKY_AT_BOTTOM / NOT_STICKY /
                                            STICKY_AT_TOP, //10
                                        </b>
                                    </li>
                                    <li>
                                        stickyPlayerPosition: 10
                                        <br />
                                    </li>
                                    <li>
                                        stickyPlayerPositionMobile: 5<br />
                                    </li>
                                </ol>
                                <Stack>
                                    Please make sure that div id and tgtEl should bear same value
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack spacing={3} id="meta-human-widget-integration">
                            <h3>Content Generation</h3>
                        </Stack>
                        <Stack spacing={3} sx={{ p: { sm: 4, xs: 1 } }}>
                            <Stack spacing={5}>
                                <Stack spacing={3} id="request-url">
                                    <Stack spacing={1}>
                                        <h4>Request</h4>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <DisplayCode children={requestUrl} />
                                    </Stack>
                                </Stack>

                                <Divider />

                                <Stack spacing={2} id="headers">
                                    <Stack spacing={1}>
                                        <h4>Header parameters</h4>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Field</TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            Field Type{' '}
                                                        </TableCell>
                                                        <TableCell align="left">Value</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {headers.map((row) => (
                                                        <TableRow
                                                            key={row}
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                    {
                                                                        border: 0
                                                                    }
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.parameter}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.meaning}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.sampleValues}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <DisplayCode children={headersobject} />
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Stack spacing={3} id="request-body">
                                    <Stack spacing={1}>
                                        <h4>Request body</h4>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Field</TableCell>
                                                        <TableCell align="left">
                                                            {' '}
                                                            Field Type{' '}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            Field Description
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {request.map((row) => (
                                                        <TableRow
                                                            key={row}
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                    {
                                                                        border: 0
                                                                    }
                                                            }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {row.parameter}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.meaning}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.sampleValues}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <DisplayCode children={requestobject} />
                                    </Stack>
                                </Stack>
                                <Stack spacing={3} id="response">
                                    <Stack spacing={1}>
                                        <h4>Response</h4>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <DisplayCode children={response} />
                                    </Stack>
                                </Stack>

                                <Divider />
                                <Stack spacing={3} id="sample request">
                                    <Stack spacing={1}>
                                        <h4>Sample request</h4>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <DisplayCode children={sampleRequest} />
                                    </Stack>
                                </Stack>

                                <Divider />
                                <Stack spacing={3} id="sample request">
                                    <Stack spacing={1}>
                                        <h4>Sample multi image request</h4>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <DisplayCode children={sampleMultiImageRequest} />
                                    </Stack>
                                </Stack>

                                <Divider />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}{' '}
        </div>
    );
}

export default Template3Implementation;
