import React from 'react';
import { useSelector } from 'react-redux';
import DrawerNavbarPrivate from './drawerNavbarPrivate';
import DrawerNavbarPublic from './drawerNavbarPublic';

export default function DrawerNavbar({ click }) {
    const { isLoggedIn } = useSelector((state) => state).authReducer;

    if (isLoggedIn) {
        return <DrawerNavbarPrivate click={click} />;
    }
    return <DrawerNavbarPublic click={click} />;
}
