import { ActionTypes } from '../types/audioShotsActionTypes';

const initialState = {
    users: [],
    profileFeed: [],
    recommendedFeed: [],
    selectedAudioShot: {},
    selectedReply: {},
    audioShotQueue: [],
    isAudioShotPlaying: false
};

// eslint-disable-next-line default-param-last,import/prefer-default-export
export const audioShotsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_USERS: {
            return { ...state, ...payload };
        }
        case ActionTypes.SELECTED_AUDIO_SHOT: {
            return { ...state, ...payload };
        }
        case ActionTypes.SELECTED_REPLY: {
            return { ...state, ...payload };
        }
        case ActionTypes.SET_AUDIO_SHOT_PLAYER_QUEUE: {
            return { ...state, ...payload };
        }
        case ActionTypes.SET_IS_PLAYING: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
};
