import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import { isDebug } from './constants';

const firebaseDebugConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_DEBUG_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DEBUG_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DEBUG_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_DEBUG_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_DEBUG_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_DEBUG_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_DEBUG_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_DEBUG_MEASUREMENT_ID
};

const firebaseDevConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_DEV_MEASUREMENT_ID
};

const firebaseConfig = isDebug ? firebaseDebugConfig : firebaseDevConfig;

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

firebase.analytics();

export const logEvent = async (name, params) => {
    try {
        await firebase.analytics().logEvent(name, params);
    } catch (error) {
        console.log('Error logging event ', error);
    }
};

export default firebase;
