import React, { useEffect, useRef, useState } from 'react';
import {
    FaDownload,
    FaMicrophone,
    FaRegThumbsUp,
    FaThumbsUp,
    FaWhatsapp,
    FaShare
} from 'react-icons/fa';
import { MdHeadphones, MdSettings } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { Chip } from '@mui/material';
import styles from './styles.module.css';
import { getAudioShotByIdApi, getReplyByShotIdApi } from '../../utils/api';
import { setSignInOptionsVisible } from '../../redux/actions/authActions';
import SignInOptionsCard from '../../components/signInOptionsCard';
import CircularPlayButton from '../../components/circularPlayButton';
import ReplyCard from '../../components/replyCard';

export default function AudioShotPage(props) {
    const { shotId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const { isLoggedIn, showSignInOptions } = useSelector((state) => state).authReducer;

    const [ASData, setASData] = useState({});
    const [replies, setReplies] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isOptionMenuOpen, setOptionMenuOpen] = useState(false);
    // eslint-disable-next-line react/destructuring-assignment,no-unused-vars
    const [isLiked, setIsLiked] = useState(props.isLiked);

    const audioRef = useRef(null);

    let signInOptions;

    const checkLogginStatus = () => {
        if (!isLoggedIn) {
            dispatch(setSignInOptionsVisible(true));
        }
    };

    useEffect(() => {
        const AudioShotOptionsMenu = document.getElementsByClassName(styles.audioShotOptionsMenu);
        const len = AudioShotOptionsMenu.length;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < len; ++i) {
            if (isOptionMenuOpen) {
                AudioShotOptionsMenu[i].style.display = 'grid';
            } else {
                AudioShotOptionsMenu[i].style.display = 'none';
            }
        }
    }, [isOptionMenuOpen]);

    /* window.onclick = function (event) {
        const AudioShotOptionsMenu = document.getElementsByClassName(styles.audioShotOptionsMenu);
        if (event.target !== AudioShotOptionsMenu[0]) {
            AudioShotOptionsMenu[0].style.display = "none";
        }
    } */

    useEffect(() => {
        getAudioShotByIdApi(shotId).then((data) => setASData(data));
        getReplyByShotIdApi(shotId, '').then((data) => setReplies(data));
    }, []);

    if (showSignInOptions) {
        signInOptions = <SignInOptionsCard style={{ display: 'block' }} />;
    }

    const { src } = ASData;
    const iconButtonStyle = {
        '@media (max-width:500px)': {
            height: 30,
            width: 30
        }
    };
    const chipStyle = {
        height: 25,
        zIndex: 1,
        fontFamily: 'Rubik-Medium',
        backgroundColor: 'black',
        color: 'white',
        '.MuiChip-icon': {
            height: 18,
            width: 18
        },
        '@media (max-width: 500px)': {
            height: 20,
            left: 15,
            bottom: 15,
            fontSize: 10,
            '.MuiChip-icon': {
                height: 15,
                width: 15
            }
        }
    };
    return (
        <div className={styles.audioShotContainerBackground}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
                onClick={() => {
                    history.goBack();
                }}
            />
            <div className={styles.audioShotContainer}>
                <div className={styles.audioShot}>
                    <img alt="Audio Shot BG" src={ASData.image} />
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video ref={audioRef} src={src} />
                    <div className={styles.audioShotPlayer}>
                        <CircularPlayButton data={ASData} audioRef={audioRef} isPage />
                    </div>
                    <div className={styles.chipSet}>
                        {/* <Chip */}
                        {/*    sx={chipStyle} */}
                        {/*    icon={<MdHeadphones color={"white"}/>} */}
                        {/*    label={`${audioRef.current ? formatDuration(audioRef.current.duration) : 0} min`} /> */}
                        <Chip
                            sx={chipStyle}
                            icon={<MdHeadphones color="white" />}
                            label={`${ASData.views} time`}
                        />
                    </div>
                </div>
                <div className={styles.audioShotProfileInfo}>
                    <img
                        className={styles.audioShotProfilePic}
                        alt="profile pic"
                        src={ASData.userImage}
                        onClick={() => history.push(`/app/p/${ASData.userid}`)}
                    />
                    <div className={styles.audioShotProfileDetails}>
                        <label className={styles.profileName}>{ASData.userName}</label>
                        <p className={styles.audioShotCreated}>{ASData.timestamp}</p>
                    </div>
                    {/* <span className={styles.audioShotOptionsButton} */}
                    {/*      onClick={() => setOptionMenuOpen(!isOptionMenuOpen)}> */}
                    {/*        <HiDotsVertical size={30}/> */}
                    {/* </span> */}
                    <div className={styles.audioShotOptionsMenu}>
                        <div className={styles.audioShotOptionsMenuItems}>
                            <FaDownload size={20} />
                            <span>Download</span>
                        </div>
                        <div className={styles.audioShotOptionsMenuItems}>
                            <FaShare size={20} />
                            <span>Share</span>
                        </div>
                        <div className={styles.audioShotOptionsMenuItems}>
                            <MdSettings size={20} />
                            <span>Settings</span>
                        </div>
                    </div>
                </div>
                <div className={styles.audioShotDesc}>
                    <p className={styles.audioShotTitle}>{ASData.title}</p>
                    <p className={styles.audioShotCategory}>{ASData.categoryName}</p>
                </div>
                <div className={styles.audioShotReplies}>
                    <p>Replies</p>
                    {replies && replies.map((data) => <ReplyCard key={data.id} data={data} />)}
                </div>
                <div className={styles.audioShotFooter}>
                    <div>
                        <IconButton sx={iconButtonStyle} onClick={checkLogginStatus}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {!isLoggedIn ? (
                                <FaRegThumbsUp />
                            ) : isLiked ? (
                                <FaThumbsUp />
                            ) : (
                                <FaRegThumbsUp />
                            )}
                        </IconButton>
                        <p>{ASData.likes} Likes</p>
                    </div>
                    <div>
                        <IconButton sx={iconButtonStyle} onClick={checkLogginStatus}>
                            <FaWhatsapp />
                        </IconButton>
                        <p>Shares</p>
                    </div>
                    <div>
                        <IconButton
                            sx={{
                                '@media (max-width:500px)': {
                                    height: 27,
                                    width: 27
                                }
                            }}
                            onClick={checkLogginStatus}
                        >
                            <FaMicrophone />
                        </IconButton>
                        <p>{ASData.replyCount} Replies</p>
                    </div>
                </div>
            </div>
            {signInOptions}
        </div>
    );
}
