import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = Math.round(value - minute * 60);
    return `${minute}:${secondLeft < 9 ? `0${secondLeft}` : secondLeft}`;
}

export const secondsToDate = (secs) => {
    const t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    return t;
};

export const timeStamp = (time) => {
    const dateNow = Date.now();
    const diff = dateNow - time * 1000;
    if (diff <= 300000) {
        return 'Just now';
    }
    if (diff > 300000 && diff < 3600000) {
        return `${Math.round(diff / 60000)} mins ago`;
    }
    if (diff >= 3600000 && diff < 86400000) {
        return `${Math.round(diff / 3600000)} hr ago`;
    }
    if (diff >= 86400000 && diff <= 172800000) {
        // eslint-disable-next-line no-underscore-dangle
        const _time = new Date(time * 1000).toLocaleTimeString();
        const hr = _time.slice(0, 2);
        // eslint-disable-next-line no-underscore-dangle
        const __time =
            hr > 13 ? `${hr - 12 + _time.slice(2, 5)} PM` : `${hr + _time.slice(2, 5)} AM`;
        return `Yesterday, ${__time}`;
    }
    if (Math.round(diff / 86400000) > 1 && diff / 86400000 < 30) {
        return `${Math.round(diff / 86400000)} days ago`;
    }
    if (Math.round(diff / 2592000000) >= 1 && Math.round(diff / 2592000000) < 13) {
        const month = Math.round(diff / 2592000000);
        return month < 2 ? `${month} month ago` : `${month} months ago`;
    }
    if (Math.round(diff / 2592000000) > 13) {
        const year = Math.round(diff / 2592000000) - 12;
        return year < 2 ? `${year} year ago` : `${year} years ago`;
    }
    return Math.round(diff / 2592000000);
};

export function useQuery({ search } = useLocation()) {
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const paginate = (arr, size = arr.length) => {
    return arr.reduce((acc, val, i) => {
        const idx = Math.floor(i / size);
        const page = acc[idx] || (acc[idx] = []);
        page.push(val);
        return acc;
    }, []);
};

export function fancyTimeFormat(duration) {
    const hrs = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = Math.floor(duration) % 60;

    let ret = '';

    if (hrs > 0) {
        ret += `${hrs}:${mins < 10 ? '0' : ''}`;
        ret += `${mins} : ${secs < 10 ? '0' : ''}`;
        ret += `${secs} hours`;
    } else if (mins > 0) {
        ret += `${mins} : ${secs < 10 ? '0' : ''}`;
        ret += `${secs} mins`;
    } else {
        ret += `${secs} secs`;
    }

    return ret;
}

export function getTime(secs) {
    const date = new Date(secs);
    let time = `${date.getHours()}:${date.getMinutes()}`;
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? 'AM' : 'PM';
        time[0] = +time[0] % 12 || 12;
    }
    time = time.join('');

    return time;
}

export function getDateTime(secs) {
    let date = new Date(secs);
    const days = ['SUN', 'MON', 'TUES', 'WED', 'THURS', 'FRI', 'SAT'];
    const months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUNE',
        'JULY',
        'AUG',
        'SEPT',
        'OCT',
        'NOV',
        'DEC'
    ];

    date = `${days[date.getDay()]} ${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()} ${
        months[date.getMonth()]
    } ${getTime(secs)}`;
    return date;
}

export const addNSecondsDelay = (n) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, n * 1000);
    });
};
