import { Link, NavLink, useHistory } from 'react-router-dom';
import { FaBook, FaHashtag, FaHeadset, FaHome } from 'react-icons/fa';
import { MdPermCameraMic, MdPrivacyTip, MdSettings } from 'react-icons/md';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiTwotoneAudio } from 'react-icons/ai';
import { IoMdContacts } from 'react-icons/io';
import { IoLogOut } from 'react-icons/io5';
import React from 'react';
import styles from './styles.module.css';
import firebase from '../../../utils/firebaseClient';

function DrawerNavbarPrivate({ click }) {
    const auth = firebase.auth();
    const history = useHistory();

    return (
        <div className={styles.navbarBackground} onClick={click}>
            <nav className={styles.drawerNavbar}>
                <div className={styles.drawerNavbarProfile}>
                    <img
                        alt="profile Pic"
                        src="https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/Web%2Fjustbaat-round-logo.png?alt=media&token=6060e335-11b4-4847-8977-e63e606ce96b"
                    />
                    <div>
                        <span>Dil Ki Baat - Justbaat</span>
                        <label>Justbaat</label>
                    </div>
                </div>
                <ul>
                    <NavLink activeClassName={styles.active} to="/home">
                        <li>
                            <FaHome size={25} color="white" />
                            <span>Home</span>
                        </li>
                    </NavLink>
                    <NavLink activeClassName={styles.active} to="/explore">
                        <li>
                            <FaHashtag size={25} color="white" />
                            <span>Explore</span>
                        </li>
                    </NavLink>
                    <NavLink activeClassName={styles.active} to="/rooms">
                        <li>
                            <MdPermCameraMic size={25} color="white" />
                            <span>Rooms</span>
                        </li>
                    </NavLink>
                    <NavLink activeClassName={styles.active} to="/jockeys">
                        <li>
                            <FaHeadset size={25} color="white" />
                            <span>Jockeys</span>
                        </li>
                    </NavLink>
                    <NavLink activeClassName={styles.active} to="/profile">
                        <li>
                            <BsFillPersonFill size={25} color="white" />
                            <span>Profile</span>
                        </li>
                    </NavLink>
                    <Link to="/">
                        <li>
                            <AiTwotoneAudio size={25} color="white" />
                            <span>Audio Shot</span>
                        </li>
                    </Link>
                    <Link to="/">
                        <li>
                            <IoMdContacts size={25} color="white" />
                            <span>Live Room</span>
                        </li>
                    </Link>
                    <Link to="/about-us">
                        <li>
                            <FaBook size={25} color="white" />
                            <span>About us</span>
                        </li>
                    </Link>
                    <Link to="/privacy-policy">
                        <li>
                            <MdPrivacyTip size={25} color="white" />
                            <span>Privacy Policy</span>
                        </li>
                    </Link>
                    <Link to="/">
                        <li>
                            <MdSettings size={25} color="white" />
                            <span>Settings</span>
                        </li>
                    </Link>
                    <button onClick={() => auth.signOut().then(() => history.push('/'))}>
                        <li>
                            <IoLogOut size={25} color="white" />
                            <span>Logout</span>
                        </li>
                    </button>
                </ul>
            </nav>
        </div>
    );
}

export default DrawerNavbarPrivate;
