import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { logo } from '../../../utils/constants';

function NavbarTop({ selected, children }) {
    return (
        <div className={styles.navbar}>
            <Link to="/">
                <img alt="logo" src={logo} height={50} />
            </Link>
            <h1 className={styles.selected}>{selected}</h1>
            <div className={styles.navLeft}>{children}</div>
        </div>
    );
}

export default NavbarTop;
