import React from 'react';
import { Button } from '@mui/material';
import { IoCopy } from 'react-icons/io5';
import styled from '@emotion/styled';
import styles from './styles.module.css';

const CustomButton = styled((props) => <Button {...props} />)({
    position: 'absolute',
    top: 2,
    right: 2
});

function DisplayCode({ children }) {
    const copyToClipboard = (text) => {
        if (!navigator.clipboard) {
            console.log('failed');
        } else {
            navigator.clipboard.writeText(text).then(() => console.log('COPIED'));
        }
    };

    return (
        <div className={styles['embed-code']}>
            <CustomButton onClick={() => copyToClipboard(children)} startIcon={<IoCopy />}>
                COPY
            </CustomButton>
            <pre>
                <code>{children}</code>
            </pre>
        </div>
    );
}

export default DisplayCode;
