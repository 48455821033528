import React, { useEffect } from 'react';
import { FaGooglePlay } from 'react-icons/fa';
import styles from './styles.module.css';
import SignInButton from '../../components/signInButton';

function Room() {
    const watermark =
        'https://firebasestorage.googleapis.com/v0/b/justbaat-dev.appspot.com/o/Web%2FLogo-500-trans.png?alt=media&token=36764d17-6c9e-4e74-b390-a4194a4030f7';

    useEffect(() => {
        const script = document.createElement('script');

        script.innerHTML = `
            (function (w, d, s, o, f, js, fjs) {
                w[o] =
                    w[o] ||
                    function () {
                        (w[o].q = w[o].q || []).push(arguments);
                    };
                (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
                js.id = o;
                js.src = f;
                js.async = 1;
                fjs.parentNode.insertBefore(js, fjs);
            })(window, document, 'script', 'jbWidget', 'https://justbaat-debug-widgets.web.app/jb-widget.min.js');
            jbWidget('init', {
                appId: '58114dbb-e4f3-4cfd-a32c-1a46e5c4b557',
                secret: 'c33b5d5e-79f5-4ed9-981f-66c432024429',
                partner_id: 'RAJASTHAN_PATRIKA',
                render: [
                    {
                        wid: 'floating-button',
                        tgtEl: 'jb-room-container',
                        uiConfig: {
                            bgColor: '#094794',
                            width: '100%'
                        },
                        widgetConfig: {
                            roomId: '1qdJxSC7fq13Be8kUC81'
                        }
                    }
                ]
            });
        `;

        document.body.appendChild(script);
    }, []);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.main_container}>
                <div className={styles.container}>
                    <div className={styles.header}>Room</div>
                    <div className={styles.content}>
                        <img alt="watermark" className={styles.watermark} src={watermark} />
                        <h3>4 best things that LIVE audio enables for you</h3>
                        <p>
                            We all have tales and different stories to tell our friends,
                            communities, and the rest of the world. Don’t we? Storytelling helps to
                            form a meaningful connection between people. We already know that speech
                            and sound are the building blocks for connecting with each other. As a
                            result, good audio experiences may be both immersive and intimate. It
                            makes you feel as if you are hanging out with your friends even if
                            you're thousands of miles apart. Audio easily fits into our hectic
                            schedules, allowing us to be inspired by fresh ideas and converse with
                            like-minded people without feeling rushed. You can join conversations
                            easily anytime and anywhere.
                        </p>
                        <div>
                            <p>How live audio rooms help content creators or brands to grow -</p>
                            <ul>
                                <li>
                                    <p>Enable actual conversations</p>
                                    <p>
                                        Brands or content creators can deeply connect with their
                                        audience. The real-time interaction with your audience can
                                        establish meaningful connections.
                                    </p>
                                </li>

                                <li>
                                    <p>Collect instantaneous reactions</p>
                                    <p>
                                        As a brand or an influencer (content creator), you want your
                                        audience to have interactive conversations where users can
                                        react instantaneously. Live audio is a perfect solution for
                                        this. You can integrate different tools in your live room
                                        like polls, voting, etc. This helps the content creators to
                                        get opinions from their audience on the go (LIVE).
                                    </p>
                                </li>

                                <li>
                                    <p>Choose from different formats</p>
                                    <p>
                                        You can use different formats for live audio. Be it
                                        interactive podcasts, interview rooms, panellists, or guest
                                        rooms. It helps you go creative with your content and grab
                                        more attention.
                                    </p>
                                </li>

                                <li>
                                    <p>Monetization opportunities</p>
                                    <p>
                                        Live room enables the audience to support their favourite
                                        creator through funds, or donate to causes they care about.
                                        A perfect and easy way to monetize your content.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <h3>Vernacular Media Apps Are on The Rise in India</h3>
                        <p>
                            According to a poll conducted by KPMG Google, 70 percent of Indians
                            prefer digital content created in their native tongue.
                            <br /> 88 percent of Indian internet users respond more positively to a
                            digital advertisement in their own language. Also, 90% of the video is
                            consumed in the local language.
                            <br />
                            Despite the fact that India has the 2nd largest internet user base in
                            the world. it only covers 41% population of the country.
                            <br />
                            This indicates that when it comes to penetration of digital content in
                            India, vernacular and topical material will reign supreme.
                            <br />
                        </p>
                        <p>
                            Quick fact - Over 300 million posts are published on Twitter every day.
                            Out of this, about 75,000 are made in vernacular Indian languages.
                        </p>
                        <p>
                            Vernacular content resonates with people. It is easy to reach a larger
                            audience and gain more traction. Second, including regional variations
                            in your content assists you to receive a better response from your
                            target viewers.
                        </p>
                        <div>
                            <p>What's Behind the Rapid Growth of Vernacular Language Apps?</p>
                            <ul>
                                <li>
                                    Strong social bonds have been discovered to be an essential
                                    driver in both online and offline relationships. Vernacular
                                    content helps people feel more connected to one another.
                                </li>
                                <li>Content in local languages aids in easy comprehension.</li>
                                <li>
                                    Response on vernacular digital ads is phenomenal! Of course,
                                    because of the above-mentioned reasons.
                                </li>
                                <li>
                                    9 out of 10 new internet users in the next decade will come from
                                    the vernacular group, and they will likely use services in
                                    languages other than English.
                                </li>
                                <li>
                                    Start-ups in digital media are pouring money into this area,
                                    realizing that expanding vernacular reach is critical to
                                    reaching the tier-2 to tier-4 audience.
                                </li>
                                <li>
                                    Many city dwellers have begun to appreciate services and content
                                    in their native languages like watching online content or using
                                    other apps.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <SignInButton
                            style={{
                                placeSelf: 'center',
                                background: '#0a53c1',
                                border: '2px #ffffff solid',
                                boxShadow: '0 0 .25em .25em rgba(0, 0, 0, .2)'
                            }}
                            onClick={() =>
                                window.open(
                                    'https://play.google.com/store/apps/details?id=com.justbaat.android',
                                    '_blank'
                                )
                            }
                            icon={<FaGooglePlay size={35} />}
                            label_1="GET APP ON"
                            label_2="Google Play"
                        />
                    </div>
                </div>
            </div>
            <div id="jb-room-container" />
        </div>
    );
}

export default Room;
