import React, { useEffect, useRef, useState } from 'react';
import {
    FaDownload,
    FaMicrophone,
    FaRegThumbsUp,
    FaThumbsUp,
    FaWhatsapp,
    FaShare
} from 'react-icons/fa';
import { MdHeadphones, MdSettings } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { Chip } from '@mui/material';
import { setSignInOptionsVisible } from '../../redux/actions/authActions';
import CircularPlayButton from '../circularPlayButton';
import styles from './styles.module.css';
import { timeStamp } from '../../utils';

export default function AudioShot(props) {
    const { data, index } = props;
    const shotOwnerName = data.userName.replace(' ', '');
    const dispatch = useDispatch();
    const history = useHistory();

    const { isLoggedIn } = useSelector((state) => state).authReducer;
    // eslint-disable-next-line no-unused-vars
    const [isOptionMenuOpen, setOptionMenuOpen] = useState(false);
    // eslint-disable-next-line react/destructuring-assignment,no-unused-vars
    const [isLiked, setIsLiked] = useState(props.isLiked);

    const audioRef = useRef(null);

    useEffect(() => {
        const AudioShotOptionsMenu = document.getElementsByClassName(styles.audioShotOptionsMenu);
        if (isOptionMenuOpen) {
            AudioShotOptionsMenu[index].style.display = 'grid';
        } else {
            AudioShotOptionsMenu[index].style.display = 'none';
        }
    }, [isOptionMenuOpen]);

    /* window.onclick = function (event) {
        const AudioShotOptionsMenu = document.getElementsByClassName(styles.audioShotOptionsMenu);
        if (event.target !== AudioShotOptionsMenu[index]) {
            AudioShotOptionsMenu[index].style.display = "none";
        }
    } */

    const checkLogginStatus = () => {
        if (!isLoggedIn) {
            dispatch(setSignInOptionsVisible(true));
        }
    };

    const { src } = data;
    const iconButtonStyle = {
        '@media (max-width:500px)': {
            height: 30,
            width: 30
        }
    };
    const chipStyle = {
        height: 25,
        zIndex: 1,
        fontFamily: 'Rubik-Medium',
        backgroundColor: 'black',
        color: 'white',
        '.MuiChip-icon': {
            height: 18,
            width: 18
        },
        '@media (max-width: 500px)': {
            height: 20,
            fontSize: 10,
            '.MuiChip-icon': {
                height: 15,
                width: 15
            }
        }
    };
    return (
        <div className={styles.audioShotContainer}>
            <div
                className={styles.audioShotProfileInfo}
                onClick={() => history.push(`/app/p/${data.userid}`)}
            >
                {' '}
                {/* onClick={() => history.push(`/app/p/${data["userid"]}`)} */}
                <img
                    className={styles.audioShotProfilePic}
                    alt="profile pic"
                    src={data.userImage}
                />
                <div className={styles.audioShotProfileDetails}>
                    <label className={styles.profileName}>{data.userName}</label>
                    <p className={styles.audioShotCreated}>
                        {/* eslint-disable-next-line no-underscore-dangle */}
                        {timeStamp(data.created_date._seconds)}
                    </p>
                </div>
                {/* <span className={styles.audioShotOptionsButton} */}
                {/*      onClick={() => setOptionMenuOpen(!isOptionMenuOpen)}> */}
                {/* <HiDotsVertical size={30}/></span> */}
                <div className={styles.audioShotOptionsMenu}>
                    <div className={styles.audioShotOptionsMenuItems}>
                        <FaDownload size={20} />
                        <span>Download</span>
                    </div>
                    <div className={styles.audioShotOptionsMenuItems}>
                        <FaShare size={20} />
                        <span>Share</span>
                    </div>
                    <div className={styles.audioShotOptionsMenuItems}>
                        <MdSettings size={20} />
                        <span>Settings</span>
                    </div>
                </div>
            </div>
            <div className={styles.audioShotDesc}>
                <p className={styles.audioShotTitle}>{data.title}</p>
                <p className={styles.audioShotCategory}>{data.categoryName}</p>
            </div>
            <div
                className={styles.audioShot}
                onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/app/s/${shotOwnerName}/audio-shots/${data.id}`);
                }}
            >
                <img
                    alt="Audio Shot BG"
                    src={data.image}
                    /* style={src && src.includes(".mp4") ? {filter: "blur(.05em)"} : {}} */
                />
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video ref={audioRef} src={src} />
                <div className={styles.audioShotPlayer}>
                    <CircularPlayButton data={data} audioRef={audioRef} isPage={false} />
                </div>
                <div className={styles.chipSet}>
                    {/* <Chip */}
                    {/*    sx={chipStyle} */}
                    {/*    icon={<MdHeadphones color={"white"}/>} */}
                    {/*    label={`${audioRef.current ? formatDuration(audioRef.current.duration) : 0} min`} /> */}
                    <Chip
                        sx={chipStyle}
                        icon={<MdHeadphones color="white" />}
                        label={`${data.views} time`}
                    />
                </div>
            </div>
            <div className={styles.audioShotFooter}>
                <div>
                    <IconButton sx={iconButtonStyle} onClick={checkLogginStatus}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {!isLoggedIn ? (
                            <FaRegThumbsUp />
                        ) : isLiked ? (
                            <FaThumbsUp />
                        ) : (
                            <FaRegThumbsUp />
                        )}
                    </IconButton>
                    <p>{data.likes} Likes</p>
                </div>
                <div>
                    <IconButton sx={iconButtonStyle} onClick={checkLogginStatus}>
                        <FaWhatsapp />
                    </IconButton>
                    <p>Shares</p>
                </div>
                <div>
                    <IconButton
                        sx={{
                            '@media (max-width:500px)': {
                                height: 27,
                                width: 27
                            }
                        }}
                        onClick={() =>
                            history.push(`/app/s/${shotOwnerName}/audio-shots/${data.id}`)
                        }
                    >
                        <FaMicrophone />
                    </IconButton>
                    <p>{data.replyCount} Replies</p>
                </div>
            </div>
        </div>
    );
}
