import AxiosApi from '../services/axios-api';

export const widgetShown = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/audioshot/incrementStats?type=widget_shown&id=${id}`,
        'widget_shown'
    ).then();
};

export const widgetClicked = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/audioshot/incrementStats?type=widget_clicked&id=${id}`,
        'widget_shown'
    ).then();
};

export const audioShotPlayed = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/audioshot/incrementStats?type=audioshot_played&id=${id}`,
        'widget_shown'
    ).then();
};

export const audioShotCompleted = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/audioshot/incrementStats?type=audioshot_completed&id=${id}`,
        'widget_shown'
    ).then();
};

export const engagementTime = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/audioshot/incrementStats?type=engagement_time&id=${id}`,
        'widget_shown'
    ).then();
};

export const roomWidgetShown = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/room/incrementStats?type=widget_shown&roomId=${id}`,
        'widget_shown'
    ).then();
};

export const roomWidgetClicked = (id) => {
    AxiosApi.get(
        'cloud_function',
        `/sdk_api/v1/room/incrementStats?type=widget_clicked&roomId=${id}`,
        'widget_clicked'
    ).then();
};
