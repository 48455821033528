import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { logEvent } from '../../../utils/firebaseClient';
import { setSelectedAudioShot } from '../../../redux/actions/audioShotsActions';
import { audioShotCompleted, audioShotPlayed, engagementTime } from '../../../utils/analytics';

function CircularPlayButton({ data, audioRef, config, setIsPaused, isPaused }) {
    const { selectedAudioShot } = useSelector((state) => state).audioShotsReducer;
    const dispatch = useDispatch();

    const [trackProgress, setTrackProgress] = useState(0);

    const intervalRef = useRef(0);
    const engagementIntervalRef = useRef(0);
    const progressRef = useRef(null);
    const loaderRef = useRef(null);

    // eslint-disable-next-line no-unused-vars
    const openPlayer = () => {
        window.parent.postMessage(
            { type: 'popup-player', data },
            window.location !== window.parent.location ? document.referrer : document.location.href
        );
        audioRef.current?.pause();
    };

    const playAudio = () => {
        const eventParams = {
            shotCategory: data.categoryName,
            shotSubCategory: data.subCategoryName,
            shotLanguage: data.lang,
            shotCreatorName: data.creatorName || data.userName,
            shotCreatorId: data.partner_id || data.userid,
            shotId: data.id
        };
        if (!isPaused) {
            audioRef.current?.pause();
            clearInterval(intervalRef.current);
            clearInterval(engagementIntervalRef.current);
        } else {
            if (selectedAudioShot.current && selectedAudioShot !== audioRef) {
                selectedAudioShot.current.pause();
                selectedAudioShot.current.currentTime = 0;
            }
            dispatch(setSelectedAudioShot(audioRef));
            audioRef.current?.play().then();
            audioShotPlayed(data.id);
            audioRef.current?.addEventListener('pause', (event) => {
                setIsPaused(!audioRef.current.paused);
                // eslint-disable-next-line no-param-reassign
                event.path[1].style.backgroundColor = '';
            });
            clearInterval(intervalRef.current);
            clearInterval(engagementIntervalRef.current);
            intervalRef.current = setInterval(() => {
                try {
                    const { currentTime, duration, ended } = audioRef.current;
                    const position = Number(currentTime.toFixed(1));
                    if (position > 10 && position <= 10.2)
                        logEvent('audio_shot_play_10', eventParams).then();
                    if (position > 30 && position <= 30.2)
                        logEvent('audio_shot_play_30', eventParams).then();
                    if (ended) {
                        setIsPaused(false);
                        clearInterval(intervalRef.current);
                        clearInterval(engagementIntervalRef.current);
                        setTrackProgress(duration);
                    } else setTrackProgress(currentTime);

                    if (currentTime >= duration - 0.3) {
                        logEvent('audio_shot_play', eventParams).then();
                        audioShotCompleted(data.id);
                    }
                } catch (err) {
                    clearInterval(intervalRef.current);
                }
            }, 250);

            engagementIntervalRef.current = setInterval(() => {
                engagementTime(data.id);
            }, 10000);
        }
    };

    const loader = () => {
        if (loaderRef.current) {
            if (isPaused && !audioRef.current?.duration) {
                loaderRef.current.style.display = 'block';
                loaderRef.current.style.borderTopColor = config?.color;
            } else {
                loaderRef.current.style.display = 'none';
            }
        }
    };

    useEffect(() => {
        loader();
        playAudio();
    }, [isPaused]);

    useEffect(() => {
        try {
            const duration = audioRef.current?.duration;
            const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
            progressRef.current.style.setProperty(`--progress`, `${currentPercentage}`);
            loader();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }, [trackProgress]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            clearInterval(engagementIntervalRef.current);
        };
    }, []);

    return (
        <button
            style={{
                height: config?.size,
                width: config?.size,
                '--jb-circular-progress-thickness': config?.thickness,
                '--slider-color': config?.color
            }}
            className={styles['jb-circular-play-button-container']}
            onClick={(e) => {
                e.stopPropagation();
                setIsPaused(!isPaused);
            }}
        >
            <div ref={loaderRef} className={styles.loader} />
            <div ref={progressRef} className={styles['jb-radial-progress']}>
                {isPaused && !audioRef.current?.paused ? (
                    <img src="/@embed/img/pause.svg" alt="pause-icon" />
                ) : (
                    <img src="/@embed/img/play.svg" alt="play-icon" />
                )}
            </div>
        </button>
    );
}

export default CircularPlayButton;
