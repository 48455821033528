import { timeStamp } from './index';
import AxiosApi from '../services/axios-api';

export const allUsersApi = async () => {
    let data = null;
    try {
        const r = await AxiosApi /* "/v1/user/expertProfile?verified=true" */.get(
            'cloud_function',
            '/api/v1/user/expertProfile?verified=true',
            'expertProfile'
        );
        if (r.status === 'success') {
            data = r.msg;
        }
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const getUserByIdApi = async (uid) => {
    let data = 404;
    try {
        const r = await AxiosApi.get('cloud_function', `/api/v1/userById?id=${uid}`, 'userById');
        data = r.data;
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const shotsByRecomededApi = async (offsetId) => {
    let data = null;
    try {
        const r =
            await AxiosApi /* "/v2/audio-shots/shotsByRecomeded${offsetId !== "" ? '?offset='+offsetId : ""}`" */.get(
                'cloud_function',
                `/api/v2/audio-shots/shotsByRecomeded` +
                    `${offsetId !== '' ? `?offset=${offsetId}` : ''}`,
                'shotsByRecomeded'
            );
        if (r.status === 'success') data = r.data;
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const getAudioShotByIdApi = async (uid) => {
    let data = null;
    try {
        const r = await AxiosApi /* "/v1/audio-shots/getById" */.get(
            'cloud_function',
            `/api/v1/audio-shots/getById?id=${uid}`,
            'getById'
        );
        if (r.status === 'success') {
            data = r.data;
            // eslint-disable-next-line no-underscore-dangle
            data.timestamp = timeStamp(data.created_date._seconds);
        }
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const feedByUserIdApi = async (uid, offsetId) => {
    let data = null;
    try {
        const r = await AxiosApi /* "/v2/audio-shots/feedbyuserid" */.get(
            'cloud_function',
            `/api/v2/audio-shots/feedbyuserid?uid=${uid}` +
                `${offsetId !== '' ? `&offset=${offsetId}` : ''}`,
            'feedbyuserid'
        );
        if (r.status === 'success') data = r.data;
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const getActiveRoomsApi = async () => {
    let data = null;
    try {
        const r = await AxiosApi /* "v1/getActiveRooms?state=closed&showInScheduled=false" */.get(
            'cloud_function',
            `/api/v1/getActiveRooms?state=closed&showInScheduled=false`,
            'getActiveRooms'
        );
        if (r.status === 'success') data = r.msg;
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const getRoomByIdApi = async (id) => {
    let data = null;
    try {
        const r = await AxiosApi /* "v1/getroombyid?id=id" */.get(
            'cloud_function',
            `/api/v1/getroombyid?id=${id}`,
            'getroombyid'
        );
        if (r.status === 'success') data = r.data;
    } catch (e) {
        console.error(e);
    }
    return data;
};

export const getReplyByShotIdApi = async (shot_id, offsetId) => {
    let data = null;
    try {
        const r = await AxiosApi /* "v2/audio-shots/replies?shot_id=shot_id" */.get(
            'cloud_function',
            `/api/v2/audio-shots/replies?shot_id=${shot_id}` +
                `${offsetId !== '' ? `&offset=${offsetId}` : ''}`,
            'replies'
        );
        if (r.status === 'success') data = r.data;
    } catch (e) {
        console.error(e);
    }
    return data;
};
