/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import './adx.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function AdxPage() {
    const [showBackToTop, setShowBackToTop] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false
        });

        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop !== 0);

            if (scrollTop > 100) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);

    const handleBackToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div id="adx" className="aos-init">
            {isLoading ? (
                <div id="preloader" />
            ) : (
                <>
                    <header
                        id="adxheader"
                        className={`fixed-top ${isScrolled ? 'adxheader-scrolled' : ''} fixed-top`}
                    >
                        <div className="container d-flex align-items-center">
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <a href="/" className="adxlogo me-auto">
                                <img
                                    src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/wesite%20logo.png"
                                    alt=""
                                    className="img-fluid"
                                />
                            </a>
                            <nav id="adxnavbar" className="adxnavbar">
                                <ul>
                                    <li>
                                        <a className="nav-link scrollto" href="#about">
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a className="nav-link scrollto" href="#adxcontact">
                                            Contact
                                        </a>
                                    </li>
                                    <li>
                                        <a className="getstarted scrollto" href="#adxabout">
                                            Get Started
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </header>
                    <section id="adxhero" className="d-flex align-items-center">
                        <div className="container">
                            <div className="row">
                                <div
                                    className="aos-init col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                >
                                    <h1>
                                        Boost Your Ad Revenue to New Heights with Our Advanced AI
                                        Monetization Technology!
                                    </h1>
                                    <h2>
                                        Looking to boost your ad revenue and take your digital
                                        platform to the next level? JustBaat is the solution you've
                                        been searching for! As a cutting-edge technology company, we
                                        specialise in providing ad monetization solutions for web,
                                        app, video, and CTV publishers.
                                    </h2>
                                    <div className="d-flex justify-content-center justify-content-lg-start">
                                        <a href="#adxcontact" className="btn-get-started scrollto">
                                            Get Started
                                        </a>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-6 order-1 order-lg-2 hero-img"
                                    data-aos="zoom-in"
                                    data-aos-delay="200"
                                >
                                    <img
                                        src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/hero-img.png"
                                        className="img-fluid animated"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="adxabout" className="adxabout">
                        <div className="container" data-aos="fade-up">
                            <div className="adxsection-title">
                                <h2>About Us</h2>
                            </div>

                            <div className="row adxcontent">
                                <div className="col-lg-6">
                                    <p>
                                        We're all about helping you earn more money without
                                        sacrificing control over your platform. Our data-driven
                                        approach allows us to gather performance insights from
                                        various exchanges, so you can be confident that you're
                                        making the most of your ad space.
                                    </p>
                                    <ul>
                                        <li>
                                            <i className="bi bi-check-all" /> Open Bidding
                                            Technology
                                        </li>
                                        <li>
                                            <i className="bi bi-check-all" /> High CPM Rich
                                        </li>
                                        <li>
                                            <i className="bi bi-check-all" /> Video Ads
                                        </li>
                                        <li>
                                            <i className="bi bi-check-all" /> Managed Account
                                            Delegation
                                        </li>
                                        <li>
                                            <i className="bi bi-check-all" /> Net 30 Payment
                                        </li>
                                        <li>
                                            <i className="bi bi-check-all" /> Next Gen- Ai Video
                                            Player
                                        </li>
                                        <li>
                                            <i className="bi bi-check-all" /> Manage Inventory
                                            Delegation
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className="col-lg-6 pt-4 pt-lg-0"
                                    data-aos="fade-right"
                                    data-aos-delay="100"
                                >
                                    <img
                                        src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/why-us.png"
                                        alt="why us"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="adxclients" className="adxclients adxsection-bg">
                        <div className="container">
                            <h1 className="text-center">Demand Partners</h1>
                            <div
                                className="row aos-init aos-animate justify-content-center"
                                data-aos="zoom-in"
                            >
                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img
                                        src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/google.png"
                                        className="img-fluid"
                                        alt="a"
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img
                                        src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/PubMatic.png"
                                        className="img-fluid"
                                        alt="b"
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img
                                        src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/appnexus.png"
                                        className="img-fluid"
                                        alt="c"
                                    />
                                </div>
                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img
                                        src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/amazon-advertising-1.png"
                                        className="img-fluid"
                                        alt="d"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="adxcontact" className="adxcontact">
                        <div className="container" data-aos="fade-up">
                            <div className="adxsection-title">
                                <h2>Contact</h2>
                            </div>

                            <div className="row  justify-content-center">
                                <div className="col-lg-7 mt-5 mt-lg-0  d-flex align-items-stretch justify-content-center">
                                    <div className="text-center">
                                        <a
                                            href="mailto:partner@justbaat.com"
                                            style={{ color: '#fff' }}
                                        >
                                            <button style={{ color: '#fff' }}>Contact Us</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer id="footer">
                        <div className="footer-top">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-8 footer-contact">
                                        <img
                                            src="https://jb-gxe2dvapexhrgye3.z01.azurefd.net/optimus/web-assets/wesite%20logo.png"
                                            alt="logo"
                                            className="img"
                                        />
                                        <br />
                                        <a href="mailto:partner@justbaat.com" aria-label="contact">
                                            <h6>Contact us</h6>
                                        </a>
                                        <p>
                                            <strong>Phone:</strong> +91 8081769100
                                            <br />
                                            <strong>Email:</strong> partner@justbaat.com
                                            <br />
                                        </p>
                                    </div>
                                    <div className="col-lg-4 col-md-8 footer-links">
                                        <h4>Useful Links</h4>
                                        <ul>
                                            <li>
                                                <i className="bx bx-chevron-right" />{' '}
                                                <a href="#hero">Home</a>
                                            </li>
                                            <li>
                                                <i className="bx bx-chevron-right" />{' '}
                                                <a href="#about">About</a>
                                            </li>
                                            <li>
                                                <i className="bx bx-chevron-right" />{' '}
                                                <a href="/documentation">Documentation</a>
                                            </li>
                                            <li>
                                                <i className="bx bx-chevron-right" />{' '}
                                                <a href="./privacy-policy.html">Privacy policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 col-md-8 footer-links">
                                        <h4>Our Social Networks</h4>
                                        <p>Stay up to date with news and announcements </p>
                                        <div className="social-links mt-3">
                                            <a
                                                href="https://twitter.com/Justbaat"
                                                className="twitter"
                                                aria-label="twitter"
                                            >
                                                <i className="bx bxl-twitter" />
                                            </a>
                                            <a
                                                href="https://www.facebook.com/Justbaatnow"
                                                className="facebook"
                                                aria-label="FaceBook"
                                            >
                                                <i className="bx bxl-facebook" />
                                            </a>
                                            <a
                                                href="https://www.linkedin.com/company/justbaat/"
                                                className="linkedin"
                                                aria-label="Linkedin"
                                            >
                                                <i className="bx bxl-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container py-4">
                            <div className="copyright">
                                © Copyright{' '}
                                <strong>
                                    <span>Justbaat Mediatech Pte Limited</span>
                                </strong>
                                . All Rights Reserved
                            </div>
                        </div>
                    </footer>
                    <a
                        href="#adxhero"
                        className={`back-to-top d-flex align-items-center justify-content-center ${
                            showBackToTop ? 'active' : ''
                        }`}
                        aria-label="footer"
                        onClick={handleBackToTop}
                    >
                        <i className="bi bi-arrow-up-short" />
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <a
                        href="https://api.whatsapp.com/send?phone=+918081769100&text=Hey%20Sandeep,%20I%20am%20looking%20for%20a%20possible%20business%20partnership%20opportunity%20with%20you.%20Let's%20connect%20to%20discuss%20this."
                        className="adxfloat"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            '&:hover': {
                                color: '#fff'
                            }
                        }}
                    >
                        <i className="bi bi-whatsapp my-float" />
                    </a>
                </>
            )}
        </div>
    );
}

export default AdxPage;
