import React from 'react';
import { useSelector } from 'react-redux';
import NavbarPrivate from './navbarPrivate';
import NavbarPublic from './navbarPublic';

export default function Navbar() {
    const { isLoggedIn } = useSelector((state) => state).authReducer;

    if (isLoggedIn) {
        return <NavbarPrivate />;
    }
    return <NavbarPublic />;
}
