import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../utils/firebaseClient';
import styles from './styles.module.css';
import { setSelectedReply } from '../../redux/actions/audioShotsActions';

function ReplyCard({ data }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { selectedReply } = useSelector((state) => state).audioShotsReducer;

    const [isPlaying, setIsPlaying] = useState(false);
    const [replyProgress, setReplyProgress] = useState(0);

    const replyRef = useRef(new Audio(data.src));
    const intervalRef = useRef();

    const duration = replyRef.current.duration ? replyRef.current.duration : 0;
    const iconColor = 'rgb(28, 157, 234)';

    function formatDuration(value) {
        const minute = Math.floor(value / 60);
        const secondLeft = Math.round(value - minute * 60);
        return `${minute}:${secondLeft < 9 ? `0${secondLeft}` : secondLeft}`;
    }

    const startTimer = () => {
        const eventParams = {
            replyCreatorName: data.userName,
            replyCreatorId: data.userid,
            replyId: data.id
        };
        logEvent('reply_play_0', eventParams).then();
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (replyRef.current) {
                if (replyRef.current.ended) {
                    clearInterval(intervalRef.current);
                    logEvent('reply_play_100', eventParams).then();
                    setReplyProgress(0);
                    setIsPlaying(false);
                } else {
                    setReplyProgress(replyRef.current.currentTime);
                }
            }
        }, 100);
    };

    const onScrub = (value) => {
        clearInterval(intervalRef.current);
        replyRef.current.currentTime = value;
        setReplyProgress(replyRef.current.currentTime);
    };

    const onScrubEnd = () => {
        if (!isPlaying) setIsPlaying(true);
        startTimer();
    };

    useEffect(() => {
        if (selectedReply.current && selectedReply !== replyRef) {
            selectedReply.current.pause();
            selectedReply.current.currentTime = 0;
            selectedReply.current.onpause = () => {
                setIsPlaying(selectedReply.current.paused);
            };
        }
        if (isPlaying) {
            dispatch(setSelectedReply(replyRef));
            replyRef.current.play().then();
            startTimer();
        } else {
            replyRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        return () => {
            if (replyRef.current) {
                replyRef.current.pause();
            }
            clearInterval(intervalRef.current);
        };
    }, []);

    return (
        <div className={styles.replyCard}>
            <div className={styles.replyInfo}>
                <img
                    className={styles.userImage}
                    alt="user"
                    src={data.userImage}
                    onClick={() => history.push(`/app/p/${data.userid}`)}
                />
                <p className={styles.userName}>{data.userName}</p>
            </div>
            <div className={styles.replyPlayer}>
                <IconButton
                    className={styles.playButton}
                    aria-label={isPlaying ? 'play' : 'pause'}
                    onClick={() => setIsPlaying(!isPlaying)}
                >
                    {isPlaying && !replyRef.current.paused ? (
                        <BsPauseFill color={iconColor} />
                    ) : (
                        <BsPlayFill color={iconColor} />
                    )}
                </IconButton>
                <Slider
                    aria-label="time-indicator"
                    size="small"
                    value={replyProgress}
                    min={0}
                    step={1}
                    max={duration || 100}
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    sx={{
                        color: iconColor,
                        height: '.15em',
                        '& .MuiSlider-thumb': {
                            width: '.5em',
                            height: '.5em',
                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                            '&:before': {
                                boxShadow: '0 .1em .6em 0 rgb(28, 157, 234, .4)'
                            },
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0 0 0 .4em rgb(28, 157, 234, .1)`
                            },
                            '&.Mui-active': {
                                width: '1em',
                                height: '1em'
                            }
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.28,
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        }
                    }}
                />
                <label className={styles.replyProgressLabel}>
                    {formatDuration(replyProgress === 0 ? duration : replyProgress)}
                </label>
            </div>
        </div>
    );
}

export default ReplyCard;
