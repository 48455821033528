import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

export default function Footer(props) {
    return (
        // eslint-disable-next-line react/destructuring-assignment
        <div className={styles.footer} style={props.style}>
            <div className={styles.aboutus}>
                {/* <span className={styles.links}><Link to="./about-us">About Us</Link></span> */}
                <span className={styles.links}>
                    <Link to="./privacy-policy">Privacy Policy</Link>
                </span>
                <span className={styles.links}>
                    <Link to="./terms&conditions">Terms&Conditions</Link>
                </span>
                <label>© Copyright JustBaat.com All Rights Reserved</label>
            </div>
        </div>
    );
}
