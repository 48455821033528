import React, { useState } from 'react';
import {
    Box,
    Collapse,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    Toolbar,
    Typography
} from '@mui/material';
import { ImEmbed2 } from 'react-icons/im';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import styled from '@emotion/styled';
import { useHistory, Link } from 'react-router-dom';
import { logo } from '../../../utils/constants';
import { useQuery } from '../../../utils';

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)({
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    margin: '5px 10px',
    borderRadius: 10,
    color: '#000',
    '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: '#000'
    }
});

const ListItemSubStyle = styled((props) => <ListItemButton {...props} />)({
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    margin: '5px 10px',
    borderRadius: 10,
    color: '#000',
    '&:before': {
        width: 20,
        content: "''"
    }
});

const ListItemIconStyle = styled(ListItemIcon)({
    width: 25,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: '#efefef',
    '&:before': { display: 'block' }
};

function DocSideBar(props) {
    const { window, content, handleDrawerToggle, mobileOpen, drawerWidth } = props;

    const history = useHistory();
    const query = useQuery();

    const [openPodcast, setOpenPodcast] = useState(true);

    const appId = query.get('appId');
    const roomId = query.get('roomId');
    const packageId = query.get('packageId');
    const secret = query.get('secret');
    const partner_id = query.get('partner_id');

    let urlQuery = '';
    if (appId && (roomId || packageId) && secret && partner_id) {
        if (roomId)
            urlQuery = `&roomId=${roomId}&appId=${appId}&secret=${secret}&partner_id=${partner_id}`;
        else if (packageId)
            urlQuery = `&packageId=${packageId}&appId=${appId}&secret=${secret}&partner_id=${partner_id}`;
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <div>
            <Toolbar sx={{ paddingY: 4 }}>
                <Link to="/home">
                    <img style={{ height: '37px', marginTop: -10 }} alt="logo" src={logo} />
                </Link>
            </Toolbar>
            <List>
                <ListItemStyle onClick={() => setOpenPodcast(!openPodcast)}>
                    <ListItemIconStyle>
                        <ImEmbed2 />
                    </ListItemIconStyle>
                    <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                        Content Implementation
                    </Typography>
                    <ListItemIconStyle>
                        {openPodcast ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                    </ListItemIconStyle>
                </ListItemStyle>
                <Collapse in={openPodcast} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ margin: '0 20px' }}>
                        <ListItemSubStyle
                            sx={{
                                ...(content === 'metaHuman-widget-implementation' &&
                                    activeRootStyle)
                            }}
                            onClick={() =>
                                history.push(
                                    `/documentation?content=metaHuman-widget-implementation${urlQuery}`
                                )
                            }
                        >
                            <Typography sx={{ fontSize: 15 }}>MetaHuman Widget</Typography>
                        </ListItemSubStyle>
                    </List>
                </Collapse>
            </List>
        </div>
    );

    return (
        <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth
                    }
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth
                    }
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

export default DocSideBar;
