import React from 'react';
import { Stack, CircularProgress } from '@mui/material';

function Loading({ color }) {
    return (
        <Stack
            sx={{
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <CircularProgress sx={{ color }} />
        </Stack>
    );
}

export default Loading;
