import React from 'react';
import { useHistory } from 'react-router-dom';
import { MdAccessAlarm } from 'react-icons/md';
import styles from './styles.module.css';

export default function RoomsCard({ style, data }) {
    const history = useHistory();

    return (
        <div
            className={styles.roomCard}
            style={style}
            onClick={() => history.push(`/app/r/${data.id}`)}
        >
            <img
                className={styles.cardPic}
                alt="Card Pic"
                src={data.img}
                style={data.scheduledText ? { gridRow: '1 / 4 span' } : { gridRow: '1 / 3 span' }}
            />
            <p className={styles.host}>Host: {data.host}</p>
            <p className={styles.title}>{data.title}</p>
            <p className={styles.description}>{data.description}</p>
            {data.scheduledText && (
                <div className={styles.schedule}>
                    <MdAccessAlarm size="1rem" />
                    {data.scheduledText}
                </div>
            )}
        </div>
    );
}
