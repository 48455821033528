import axios from 'axios';
import axiosRetry from 'axios-retry';
import { isDebug } from '../utils/constants';

const AxiosApiService = {
    /**
     *
     * @param {'cloud_function' | 'other'} requestType 'cloud_function' | 'other'
     * @param {string} endpoint Complete url if not cloud_function call, else only end    point
     * @param errorCode
     * @param {{action: string, id}} timeout Request timeout, default is 10000
     * @returns {Promise} Promise Object
     */
    get: async (requestType, endpoint, errorCode, timeout = 10000) => {
        try {
            if (!requestType && !endpoint && !errorCode) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    code: 123,
                    message: 'Check parameters'
                });
            }

            let url;
            let config;

            if (requestType === 'cloud_function') {
                // eslint-disable-next-line no-use-before-define
                url = createCloudFunctionUrl(endpoint);

                // eslint-disable-next-line no-use-before-define
                config = await createCloudFunctionConfig(timeout);
            } else {
                url = endpoint;
            }

            // console.log('AxiosApiService url ', url);
            // console.log("AxiosApiService congfig ", config);

            const abort = axios.CancelToken.source();
            const id = setTimeout(() => abort.cancel(`Timeout of ${config.timeout}ms.`), timeout);

            axiosRetry(axios, {
                retries: 3,
                retryCondition: (error) => {
                    // eslint-disable-next-line no-console
                    console.log('Retry_Error => ', error);
                }
            });

            const response = await axios.get(url, config);

            clearTimeout(id);

            return Promise.resolve(response.data);
        } catch (error) {
            //   crashlytics().recordError(error, ERROR[errorCode].code);
            //   logError(error, ERROR[errorCode]);

            // eslint-disable-next-line no-console
            console.log('AxiosApiService Response Error123 => ', error);

            return Promise.reject(error);
        }
    },

    /**
     *
     * @param {'cloud_function' | 'other'} requestType 'cloud_function' | 'other'
     * @param {string} endpoint Complete url if not cloud_function call, else only end point
     * @param {string} errorCode Error code for logging
     * @param {any} data Data to send with POST request
     * @param {number} timeout Request timeout, default is 10000
     * @param {boolean} fileUpload File to upload
     * @returns {Promise} Promise Object
     */
    post: async (requestType, endpoint, errorCode, data, timeout = 10000, fileUpload = false) => {
        try {
            if (!requestType && !endpoint && !errorCode && !data) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    code: 123,
                    message: 'Check parameters'
                });
            }

            let url;
            let config;

            if (requestType === 'cloud_function') {
                // eslint-disable-next-line no-use-before-define
                url = createCloudFunctionUrl(endpoint);

                // eslint-disable-next-line no-use-before-define
                config = await createCloudFunctionConfig(timeout, fileUpload);
            } else {
                url = endpoint;
            }

            // console.log("AxiosApiService url ", url);
            // console.log("AxiosApiService data ", data);
            // console.log("AxiosApiService congfig ", config);

            const abort = axios.CancelToken.source();
            const id = setTimeout(() => abort.cancel(`Timeout of ${config.timeout}ms.`), timeout);

            axiosRetry(axios, {
                retries: 3,
                retryCondition: (error) => {
                    // eslint-disable-next-line no-console
                    console.log('Retry_Error => ', error);
                }
            });

            const response = await axios.post(url, data, config);
            // console.log("responseresponseresponse ", response);
            clearTimeout(id);

            return Promise.resolve(response);
        } catch (error) {
            //   crashlytics().recordError(error, ERROR[errorCode].code);
            //   logError(error, ERROR[errorCode]);

            // console.log("AxiosApiService Error => ", error);

            return Promise.reject();
        }
    }
};

function createCloudFunctionUrl(endpoint) {
    let baseUrl;
    if (isDebug) baseUrl = 'https://asia-south1-justbaat-debug.cloudfunctions.net';
    // if (isDebug) baseUrl = 'http://localhost:5001/justbaat-debug/asia-south1';
    else baseUrl = 'https://asia-south1-justbaat-dev.cloudfunctions.net';

    return `${baseUrl}${endpoint}`;
}

async function createCloudFunctionConfig(timeout, fileUpload = false) {
    // const token = await getAuth().currentUser.getIdToken();

    // console.log({ token });

    const config = {
        headers: {
            // Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        timeout
    };

    if (fileUpload) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
}

export default AxiosApiService;

/* ********* TEST AXIOS API START ********* */
/* try {
        const axiosResponse = await AxiosApiService.get(
          "cloud_function",
          `/api/v1/room-token?rid=${this.props.channel}`,
          "JB040"
        );

        console.log("AxiosApiService Response => ", axiosResponse);
      } catch (error) {
        console.log("AxiosApiService Response Error trycatch => ", error);
      } */
/* ********* TEST AXIOS API END ********* */
