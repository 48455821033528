/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack, AppBar, Toolbar, List, ListItemButton, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import styles from './styles.module.css';
import MobileSideBar from './sidebar';

export default function Pricing() {
    const mobileCheck = function () {
        let check = false;
        // eslint-disable-next-line func-names
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    const navItems = {
        Products: ['Text to Video', 'Content Marketing', 'Video Commerce Storefront'],
        Resources: ['Documentation'],
        Pricing: [],
        Team: [],
        Contact: []
    };

    const displayList = (item) => {
        return (
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper'
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {navItems[item].map((element) => (
                    <ListItemButton>{element}</ListItemButton>
                ))}
            </List>
        );
    };

    return (
        <div>
            {!mobileCheck() ? (
                <AppBar
                    className={styles.navbar}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        boxShadow: 'none'
                    }}
                    sx={{
                        fontFamily: 'Manrope',
                        fontWeight: 700,
                        fontSize: 'large',
                        bgcolor: '#fff'
                    }}
                >
                    <div className={styles.header}>
                        <div className={styles.jbLogo}>
                            <Link to="/">
                                <img id="jb-logo" alt="JB" src="/img/home/JB.jpg" />
                            </Link>
                        </div>
                    </div>
                    <Toolbar className={styles.mobilelogo}>
                        <Stack>
                            <Box>
                                <div className={styles.navbarmenu}>
                                    <Button
                                        sx={{
                                            color: '#808080',
                                            fontStyle: 'Manrope'
                                        }}
                                        onClick={() => displayList('Products')}
                                    >
                                        Products
                                    </Button>
                                    <Link key="documentation" to="documentation">
                                        <Button
                                            sx={{
                                                color: '#808080',
                                                fontStyle: 'Manrope'
                                            }}
                                            onClick={() => displayList('documentation')}
                                        >
                                            Documentation
                                        </Button>
                                    </Link>

                                    <Link key="Pricing" to="Pricing">
                                        <Button
                                            sx={{
                                                color: '#808080',
                                                fontStyle: 'Manrope'
                                            }}
                                            onClick={() => displayList('Products')}
                                        >
                                            Pricing
                                        </Button>
                                    </Link>

                                    <Link key="Team" to="Team">
                                        <Button
                                            sx={{
                                                color: '#808080',
                                                fontStyle: 'Manrope'
                                            }}
                                            onClick={() => displayList('Team')}
                                        >
                                            Team
                                        </Button>
                                    </Link>
                                    <Link key="Contact" to="Contact">
                                        <Button
                                            sx={{
                                                color: '#808080',
                                                fontStyle: 'Manrope'
                                            }}
                                            onClick={() => displayList('Products')}
                                        >
                                            Contact
                                        </Button>
                                    </Link>
                                </div>
                            </Box>
                        </Stack>
                    </Toolbar>
                </AppBar>
            ) : (
                <MobileSideBar />
            )}

            <Stack>
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} xs={12}>
                        <div className={styles.textheader}>
                            <p>Justbaat works on a revenue sharing model</p>
                        </div>
                        <div className={styles.contactEmail}>
                            <div>
                                <Button
                                    className={styles.Contactbtn}
                                    component={Link}
                                    variant="outlined"
                                    to="/documentation"
                                >
                                    CONTACT US
                                </Button>
                            </div>

                            <div className={styles.emailfield}>
                                <Divider
                                    orientation="vertical"
                                    sx={{
                                        borderRadius: 1,
                                        bgcolor: 'black'
                                    }}
                                    flexItem
                                />
                                <input
                                    type="text"
                                    name="textfield"
                                    id="textfield"
                                    value="Enter Email"
                                    className={styles.emailInput}
                                />
                                <input
                                    name=""
                                    type="button"
                                    value="Contact Me"
                                    className={styles.emailSubmit}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                        <div className={styles.pricingImg}>
                            <img
                                alt="pricing"
                                src="https://firebasestorage.googleapis.com/v0/b/justbaat-debug.appspot.com/o/image%2Fpricing.jpg?alt=media&token=7c6b5070-ac33-4b3c-be60-c9568c8de650"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Stack>
        </div>
    );
}
