import React, { useState } from 'react';
import styles from './styles.module.css';
import DrawerNavbar from '../../../../components/navbar/drawerNavbar';
import MiddleContainerHeader from '../../../../components/middleContainerHeader';

export default function Home() {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    let drawerNavbar;

    if (isDrawerOpen) {
        drawerNavbar = <DrawerNavbar click={() => setDrawerOpen(!isDrawerOpen)} />;
    }

    return (
        <div className={styles.homeContainer}>
            <MiddleContainerHeader header="Home" onClick={() => setDrawerOpen(!isDrawerOpen)} />
            {/* <div>
                <img alt={"Comming Soon"} src={"/comming_soon.png"} height={300}/>
                <button onClick={() => auth.signOut().then(() => {
                        dispatch(setIsLoggedIn(false));
                        history.push("/");
                    }
                )}> logout
                </button>
            </div> */}
            <div className={styles.container}>
                <div className={styles.space}>
                    <h3 style={{ color: '#fafafa', alignSelf: 'center' }}>
                        Build your Live Audio Room in less than 5 Minutes{' '}
                    </h3>
                </div>
                <div className={styles.space}>
                    <a
                        href="https://justbaat.com/room"
                        target="_blank"
                        className={styles.homeButton}
                        rel="noreferrer"
                    >
                        Room Demo{' '}
                    </a>
                </div>
                <div className={styles.space}>
                    <a href="/home/documentation" className={styles.homeButton} target="_blank">
                        Documentation{' '}
                    </a>
                </div>
                <div className={styles.space}>
                    <a
                        href="https://console.justbaat.com/login"
                        target="_blank"
                        className={styles.homeButton}
                        rel="noreferrer"
                    >
                        Login{' '}
                    </a>
                </div>
            </div>
            {drawerNavbar}
        </div>
    );
}
