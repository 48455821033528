import React, { useEffect } from 'react';
import { Box, Divider, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from '../styles.module.css';
import { useQuery } from '../../../utils';
import DisplayCode from '../../../components/documentation/displayCode';
import { isDebug } from '../../../utils/constants';
import KeysTable from '../../../components/keysTable/keysTable';

const CustomImg = styled('img')(() => ({
    objectFit: 'contain',
    width: '100%'
}));

function createData(parameter, meaning, sampleValues) {
    return { parameter, meaning, sampleValues };
}

const rows = [
    createData('packageId', 'It is a package consist of audioshots', 'CS9lWrSpIvX6jv6Jqxn'),
    createData(
        'appId',
        'Unique application id. To  fetch  the appId please <a href="https://console.justbaat.com/"><b>login<b></a>.',
        '5b2f94b7-7f4a-4384-bf0d-c4bbb6jhja9f'
    ),

    createData(
        'secret',
        'Application secret. To get the secret please <a href="https://console.justbaat.com/"><b>login<b></a> .',
        '49bc0e9e4aaae0b90b061643d4a909a9e19812d38286cbjhbj84109186b6b54e7c'
    ),
    createData(
        'partner_id',
        'Partner Id is unique identifier for your account. To fetch the partner id please <a href="https://console.justbaat.com/"><b>login<b></a>',
        'JUSTBAAT_TEST'
    ),
    createData('wid', ' Defines the UI type of podcast', 'podcast-player-4'),
    createData('uiConfig.width', 'Defines the Width of the widget', '100%'),
    createData('tilesCount', 'Defines the number of the tiles present in the widget', '4'),
    createData(
        'tilesInView',
        'Defines the number of the individual tile to be displayed on a page',
        '3'
    ),
    createData(
        'categories',
        'Defines the type of your content based on your selected category',
        `[
        'Cricket',
        'Health',
        'Lifestyle'
    ]`
    ),
    createData(
        'tgtEl',
        'Defines as Target Element, your tgEl and div id should be same',
        'jb-side-widget-4'
    )
];

function Template2Implementation({ setContentMenu }) {
    // const [value, setValue] = React.useState(0);
    const value = 0;
    const query = useQuery();

    const config = {};

    useEffect(() => {
        setContentMenu([
            {
                link: 'preview',
                title: 'Preview'
            },
            {
                link: 'html-css-js',
                title: 'HTML/CSS/JS'
            },
            {
                link: 'react-js',
                title: 'React Js'
            },
            {
                link: 'wordpress',
                title: 'Wordpress'
            },
            {
                link: 'wix',
                title: 'Wix'
            }
        ]);
    }, []);

    config.appId = query.get('appId') || '<---appId--->';
    config.secret = query.get('secret') || '<---secret--->';
    config.packageId = query.get('packageId') || '<---packageId--->';
    config.partner_id = query.get('partner_id') || '<---partner_id--->';
    config.baseUrl = isDebug ? 'https://audiosdk.web.app' : 'https://audio.justbaat.com';
    config.iframeBaseUrl = isDebug ? 'https://justbaat-debug.web.app' : 'https://justbaat.com';
    config.asyncUrl = isDebug
        ? 'https://justbaat-debug-widgets.web.app/jb-widget.min.js'
        : 'https://widget.justbaat.com/jb-widget.min.js';
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    return (
        <div className={styles.main_container} id="top">
            <h1>Instory Widget</h1>
            <p>This widget shows all the rooms that are live.</p>
            <Box sx={{ paddingY: 2 }}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Template - 1" />
                        <Tab label="Template - 2" />
                    </Tabs>
                </Box> */}
                <TabPanel value={value} config={config} index={0}>
                    podcast-list-template-1
                </TabPanel>
                {/* <TabPanel value={value} config={config} index={1}>
                    podcast-list-template-2
                </TabPanel> */}
            </Box>
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index, config, ...other } = props;
    const { appId, secret, partner_id, packageId, asyncUrl } = config;

    const asyncHead = `<div id='jb-instory-widget'></div>`;

    const initConfig =
        `        (function (w, d, s, o, f, js, fjs) {` +
        '\n' +
        `            w[o] =` +
        '\n' +
        `                w[o] ||` +
        '\n' +
        `                function () {` +
        '\n' +
        `                    (w[o].q = w[o].q || []).push(arguments);` +
        '\n' +
        `                };` +
        '\n' +
        `            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);` +
        '\n' +
        `            js.id = o;` +
        '\n' +
        `            js.src = f;` +
        '\n' +
        `            js.async = 1;` +
        '\n' +
        `            fjs.parentNode.insertBefore(js, fjs);` +
        '\n' +
        `        })(window, document, 'script', 'jbWidget', '${asyncUrl}');` +
        '\n' +
        `        jbWidget('init', {` +
        '\n' +
        `            appId: "${appId}",` +
        '\n' +
        `            secret: "${secret}",` +
        '\n' +
        `            partner_id: "${partner_id}",` +
        '\n' +
        `            render: [` +
        '\n' +
        `                {` +
        '\n' +
        `                    wid: 'INSTORY_WIDGET',` +
        '\n' +
        `                    tgtEl: 'jb-instory-widget',` +
        '\n' +
        `                    uiConfig: {` +
        '\n' +
        `                        width: '100%',` +
        '\n' +
        `                    },` +
        '\n' +
        `                    widgetConfig: {` +
        '\n' +
        `                        packageId: '${packageId}',` +
        '\n' +
        `                        categories: '<---categories--->',` +
        '\n' +
        `                        tilesCount: '<---tiles Count--->',` +
        '\n' +
        `                        tilesInView: '<---tiles in View--->',` +
        '\n' +
        `                        ads: {` +
        '\n' +
        `                        enable: true` +
        '\n' +
        `                        }` +
        '\n' +
        `                    }` +
        '\n' +
        `                }` +
        '\n' +
        `            ]` +
        '\n' +
        `        });`;

    const asyncBody = `    <script>\n${initConfig}\n    </script>`;

    const reactFunctional =
        `useEffect(() => {\n` +
        `    const script = document.createElement("script");\n` +
        `\n` +
        `    script.innerHTML = \`\n${initConfig}    \`;\n` +
        `\n` +
        `    document.body.appendChild(script);\n` +
        `}, []);`;

    const reactClass =
        `componentDidMount() {\n` +
        `    const script = document.createElement("script");\n` +
        `\n` +
        `    script.innerHTML = \`\n${initConfig}    \`;\n` +
        `\n` +
        `    document.body.appendChild(script);\n` +
        `}`;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && (
                <Stack spacing={3} sx={{ p: { sm: 4, xs: 1 } }}>
                    <Stack alignItems="center" id="preview">
                        <div
                            className={styles['preview-container']}
                            onClick={() =>
                                window.open(`/@widgets/demo/podcasts/instory-widget.html`)
                            }
                        >
                            <img
                                alt="card"
                                height="100%"
                                style={{ maxHeight: '500px' }}
                                src="/@widgets/img/podcasts/instory-widget.jpeg"
                            />
                        </div>
                    </Stack>
                    <Stack spacing={5}>
                        <Stack spacing={3} id="html-css-js">
                            <Stack spacing={1}>
                                <h3>HTML / CSS / JS</h3>
                                <p>how to embed our widget on a html-css-js website.</p>
                            </Stack>

                            <Stack spacing={1}>
                                <DisplayCode children={asyncHead} />
                                <p>
                                    Place the above code at the place where you want to render the
                                    widget in
                                    {'<body>'} tag
                                </p>
                            </Stack>
                            <Stack spacing={1}>
                                <DisplayCode children={asyncBody} />
                                <p className={styles.caption}>
                                    *If Seeing {'<---<key>--->'} in place of your own keys please
                                    login to our console and come-back after creating a room. Refer
                                    to{' '}
                                    <Link to="/documentation">
                                        <b>getting-started</b>
                                    </Link>
                                    *
                                </p>
                                <ol>
                                    <li>Place the above code in {'<body>'} tag of your code.</li>
                                    <b>( OPTIONAL )</b>
                                    <li>
                                        Replace {'<---categories--->'} with <b>category</b> in{' '}
                                        <b>array</b>.
                                        <br />
                                        Eg: <b>['Cricket', 'Health', 'Lifestyle']</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles Count--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesCount: 3</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles in View--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesInView: 3</b>.
                                    </li>
                                    {/* <li>pageSize is the pagination size of the content.</li> */}
                                    {/* <li>containerId should be the id of the div.</li> */}
                                </ol>
                                <Stack>
                                    Please make sure that div id and tgtEl should bear same value
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack spacing={2} id="react-js">
                            <Stack spacing={1}>
                                <h3>ReactJs</h3>
                                <p>how to embed our widget on a react-js website.</p>
                            </Stack>

                            <Stack spacing={1}>
                                <DisplayCode children={asyncHead} />
                                <p>
                                    Place the above code at the place where you want to render the
                                    widget in
                                    {'<body>'} tag
                                </p>
                            </Stack>
                            <Stack spacing={1}>
                                <h5>React Functional Component</h5>

                                <DisplayCode children={reactFunctional} />
                                <p className={styles.caption}>
                                    *If Seeing {'<---<key>--->'} in place of your own keys please
                                    login to our console and come-back after creating a room. Refer
                                    to{' '}
                                    <Link to="/documentation">
                                        <b>getting-started</b>
                                    </Link>
                                    *
                                </p>
                                <ol>
                                    <li>
                                        Place the above code in your react functional component.
                                    </li>
                                    <b>( OPTIONAL )</b>
                                    <li>
                                        Replace {'<---categories--->'} with <b>category</b> in{' '}
                                        <b>array</b>.
                                        <br />
                                        Eg: <b>['Cricket', 'Health', 'Lifestyle']</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles Count--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesCount: 3</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles in View--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesInView: 3</b>.
                                    </li>
                                    {/* <li>pageSize is the pagination size of the content.</li> */}
                                    {/* <li>containerId should be the id of the div.</li> */}
                                </ol>
                                <Stack>
                                    Please make sure that div id and tgtEl should bear same value
                                </Stack>
                            </Stack>
                            <Stack>
                                <KeysTable rows={rows} />
                            </Stack>

                            <Stack spacing={1}>
                                <h5>React Class Component</h5>

                                <DisplayCode children={reactClass} />
                                <p className={styles.caption}>
                                    *If Seeing {'<---<key>--->'} in place of your own keys please
                                    login to our console and come-back after creating a room. Refer
                                    to{' '}
                                    <Link to="/documentation">
                                        <b>getting-started</b>
                                    </Link>
                                    *
                                </p>
                                <ol>
                                    <li>Place the above code in your react class component.</li>
                                    <b>( OPTIONAL )</b>
                                    <li>
                                        Replace {'<---categories--->'} with <b>category</b> in{' '}
                                        <b>array</b>.
                                        <br />
                                        Eg: <b>['Cricket', 'Health', 'Lifestyle']</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles Count--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesCount: 3</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles in View--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesInView: 3</b>.
                                    </li>
                                    {/* <li>pageSize is the pagination size of the content.</li> */}
                                    {/* <li>containerId should be the id of the div.</li> */}
                                </ol>
                                <Stack>
                                    Please make sure that div id and tgtEl should bear same value
                                </Stack>
                            </Stack>
                            <Stack spacing={1}>
                                <DisplayCode children={asyncBody} />
                                <p>
                                    Place the above code where ever you want to place it within your
                                    return statement.
                                </p>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack spacing={2} id="wordpress">
                            <Stack spacing={1}>
                                <h3>Wordpress</h3>
                                <p>how to embed our widget on a wordpress website.</p>
                            </Stack>

                            <Stack spacing={1}>
                                <DisplayCode children={asyncHead} />
                                <p>
                                    Place the above code at the place where you want to render the
                                    widget in
                                    {'<body>'} tag
                                </p>
                            </Stack>
                            <Stack spacing={1}>
                                <DisplayCode children={asyncBody} />
                                <p className={styles.caption}>
                                    *If Seeing {'<---<key>--->'} in place of your own keys please
                                    login to our console and come-back after creating a room. Refer
                                    to{' '}
                                    <Link to="/documentation">
                                        <b>getting-started</b>
                                    </Link>
                                    *
                                </p>
                                <ol>
                                    <li>Place the above code in {'<body>'} tag of your code.</li>
                                    <b>( OPTIONAL )</b>
                                    <li>
                                        Replace {'<---categories--->'} with <b>category</b> in{' '}
                                        <b>array</b>.
                                        <br />
                                        Eg: <b>['Cricket', 'Health', 'Lifestyle']</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles Count--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesCount: 3</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles in View--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesInView: 3</b>.
                                    </li>
                                    {/* <li>pageSize is the pagination size of the content.</li> */}
                                    {/* <li>containerId should be the id of the div.</li> */}
                                </ol>
                                <Stack>
                                    Please make sure that div id and tgtEl should bear same value
                                </Stack>
                            </Stack>
                            <Stack spacing={2}>
                                <h4>How to use the code</h4>
                                <Stack spacing={0.5}>
                                    <h5>Step 1</h5>
                                    <p>Select the page and open in editor mode</p>
                                    <CustomImg
                                        alt="demo-page"
                                        src="/img/implementation/guide/samplePage1.png"
                                    />
                                </Stack>
                                <Stack spacing={0.5}>
                                    <h5>Step 2</h5>
                                    <p>
                                        Select the place and add custom tag and paste the code here
                                    </p>
                                    <CustomImg
                                        alt="editor-mode"
                                        src="/img/implementation/guide/html.png"
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack spacing={2} id="wix">
                            <Stack spacing={1}>
                                <h3>Wix</h3>
                                <p>how to embed our widget on a wix website.</p>
                            </Stack>

                            <Stack spacing={1}>
                                <DisplayCode children={asyncHead} />
                                <p>
                                    Place the above code at the place where you want to render the
                                    widget in
                                    {'<body>'} tag
                                </p>
                            </Stack>
                            <Stack spacing={1}>
                                <DisplayCode children={asyncBody} />
                                <p className={styles.caption}>
                                    *If Seeing {'<---<key>--->'} in place of your own keys please
                                    login to our console and come-back after creating a room. Refer
                                    to{' '}
                                    <Link to="/documentation">
                                        <b>getting-started</b>
                                    </Link>
                                    *
                                </p>
                                <ol>
                                    <li>Place the above code in {'<body>'} tag of your code.</li>
                                    <b>( OPTIONAL )</b>
                                    <li>
                                        Replace {'<---categories--->'} with <b>category</b> in{' '}
                                        <b>array</b>.
                                        <br />
                                        Eg: <b>['Cricket', 'Health', 'Lifestyle']</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles Count--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesCount: 3</b>.
                                    </li>
                                    <li>
                                        Replace {'<---tiles in View--->'} with <b>number</b>
                                        <br />
                                        Eg: <b>tilesInView: 3</b>.
                                    </li>
                                    {/* <li>pageSize is the pagination size of the content.</li> */}
                                    {/* <li>containerId should be the id of the div.</li> */}
                                </ol>
                                <Stack>
                                    Please make sure that div id and tgtEl should bear same value
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider />
                    </Stack>
                </Stack>
            )}{' '}
        </div>
    );
}

export default Template2Implementation;
