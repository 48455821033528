import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaArrowRight, FaGooglePlay, FaHashtag, FaBloggerB } from 'react-icons/fa';
import { MdPermCameraMic, MdPrivacyTip, MdRecommend } from 'react-icons/md';
import styles from './styles.module.css';
import { logo, logo2 } from '../../../utils/constants';

function NavbarPublic() {
    const iconColor = '#1C9DEA';

    return (
        <nav className={styles.navbar}>
            <Link to="/home">
                <img className={styles.logo} alt="logo" src={logo} height={70} />
            </Link>
            <Link to="/home">
                <img className={styles.logoAlt} alt="logo" src={logo2} height={70} />
            </Link>
            <ul className={styles.navTop}>
                <NavLink activeClassName={styles.active} to="/recommended">
                    <li aria-label="Recommended">
                        <MdRecommend size={25} color={iconColor} />
                        <span>Recommended</span>
                    </li>
                </NavLink>
                <NavLink activeClassName={styles.active} to="/explore">
                    <li aria-label="Explore">
                        <FaHashtag size={25} color={iconColor} />
                        <span>Explore</span>
                    </li>
                </NavLink>
                <NavLink activeClassName={styles.active} to="/rooms">
                    <li aria-label="Rooms">
                        <MdPermCameraMic size={25} color={iconColor} />
                        <span>Rooms</span>
                    </li>
                </NavLink>
                {/* <Link to="/about-us"> */}
                {/*    <li aria-label={"About us"}><FaBook size={25} color={iconColor}/><span>About us</span></li> */}
                {/* </Link> */}
                <Link to="/blog">
                    <li aria-label="Blog">
                        <FaBloggerB size={25} color={iconColor} />
                        <span>Blog</span>
                    </li>
                </Link>
                <Link to="/privacy-policy">
                    <li aria-label="Privacy Policy">
                        <MdPrivacyTip size={25} color={iconColor} />
                        <span>Privacy Policy</span>
                    </li>
                </Link>
            </ul>
            <div />
            <div className={styles.navBottom}>
                {/* <div className={styles.navBottomButton} style={{width: "max-content"}}> */}
                {/*    <FaGoogle size={40} color="white"/> */}
                {/*    <span>SIGN IN WITH Google </span> */}
                {/*    <i><FaArrowRight size={30} color="white"/></i> */}
                {/* </div> */}
                <div
                    className={styles.navBottomButton}
                    style={{ width: 'max-content' }}
                    aria-label="Play Store"
                    onClick={() =>
                        window.open(
                            'https://play.google.com/store/apps/details?id=com.justbaat.android',
                            '_blank'
                        )
                    }
                >
                    <FaGooglePlay size={40} color={iconColor} />
                    <span>
                        GET IT ON
                        <br /> Play Store{' '}
                    </span>
                    <i>
                        <FaArrowRight size={30} color={iconColor} />
                    </i>
                </div>
            </div>
        </nav>
    );
}

export default NavbarPublic;
