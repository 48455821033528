import { ActionTypes } from '../types/authActionTypes';

export const setUser = (users) => {
    return {
        type: ActionTypes.SET_USERS,
        payload: { users }
    };
};

export const setIsLoggedIn = (isUser) => {
    return {
        type: ActionTypes.SET_IS_LOGGEDIN,
        payload: { isLoggedIn: isUser }
    };
};

export const setSignInOptionsVisible = (show) => {
    return {
        type: ActionTypes.SET_SIGNIN_OPTIONS_VISIBLE,
        payload: { showSignInOptions: show }
    };
};
