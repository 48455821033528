import React, { useState } from 'react';
import styles from './styles.module.css';
import DrawerNavbar from '../../../../components/navbar/drawerNavbar';
import MiddleContainerHeader from '../../../../components/middleContainerHeader';

export default function Jockeys() {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    let drawerNavbar;

    if (isDrawerOpen) {
        drawerNavbar = <DrawerNavbar click={() => setDrawerOpen(!isDrawerOpen)} />;
    }

    return (
        <div className={styles.jockeyContainer}>
            <MiddleContainerHeader header="Jockeys" onClick={() => setDrawerOpen(!isDrawerOpen)} />
            <div>
                <img alt="Comming Soon" src="/comming_soon.png" height={300} />
            </div>
            {drawerNavbar}
        </div>
    );
}
