import React, { useEffect, useState } from 'react';
import { Fab, Stack } from '@mui/material';
import styles from './styles.module.css';
import AxiosApi from '../../../../../services/axios-api';
import { Loading } from '../../../../../components/@embed';
import { getTime, useQuery } from '../../../../../utils';
import { roomWidgetShown } from '../../../../../utils/analytics';

// http://localhost:3000/embed/aajtak-room-card-1?roomId=1qdJxSC7fq13Be8kUC81&appId=58114dbb-e4f3-4cfd-a32c-1a46e5c4b557&secret=c33b5d5e-79f5-4ed9-981f-66c432024429&partner_id=RAJASTHAN_PATRIKA
function AajtakCard1() {
    const [room, setRoom] = useState(null);
    const query = useQuery();
    const roomId = query.get('roomId');
    const appId = query.get('appId');
    const secret = query.get('secret');
    const partner_id = query.get('partner_id');

    useEffect(() => {
        AxiosApi.post('cloud_function', `/sdk_api/v1/getRoomById`, 'getRoomById', {
            partner_id,
            appId,
            secret,
            roomId
        }).then((res) => {
            setRoom(res.data);
            roomWidgetShown(res.data.id);
        });
    }, []);

    const openPopUp = () => {
        // roomWidgetClicked(room.id);
        window.parent.postMessage(
            { type: 'popup-room', data: room },
            window.location !== window.parent.location
                ? document.referrer || '*'
                : document.location.href
        );
    };

    if (!room)
        return (
            <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
                <Loading />
            </Stack>
        );

    return (
        <div className={styles['jb-room-card-2']} onClick={openPopUp}>
            <div
                className={styles['jb-room-card-2-top']}
                style={{
                    background:
                        "transparent url('/@embed/img/aajtak-room-card-top.png') 0 0 no-repeat padding-box",
                    backgroundSize: 'cover'
                }}
            >
                <div className={styles['jb-room-card-2-branding']}>
                    <img src="/@embed/img/aajtak-logo.png" alt="logo" />
                    <h6>SPACE</h6>
                </div>
            </div>
            <div className={styles['jb-room-card-2-bottom']}>
                <img className={styles.watermark} src="/@embed/img/waves.svg" alt="watermark" />
                <div className={styles['jb-room-card-2-circle-icon']}>
                    <div>
                        <img src="/@embed/img/mic-headphone.svg" width="75%" alt="mic-headphone" />
                    </div>
                </div>
                <Stack justifyContent="center" alignItems="center">
                    <h4>{room.name}</h4>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <p>{room.scheduledDate ? `${getTime(room.scheduledDate)} onwards` : ' '}</p>
                    <Fab
                        variant="extended"
                        size="small"
                        sx={{
                            backgroundColor: '#e5a72c',
                            color: '#0d2347',
                            fontSize: '3vw',
                            height: '100%',
                            borderRadius: '10vw',
                            fontFamily: 'Rubik-Bold',
                            '&:hover': {
                                backgroundColor: '#ecd080'
                            }
                        }}
                    >
                        JOIN NOW
                    </Fab>
                </Stack>
            </div>
        </div>
    );
}

export default AajtakCard1;
