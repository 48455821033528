import React, { useEffect, useState } from 'react';
import { FaFacebook, FaHeadphonesAlt, FaInstagram, FaYoutube } from 'react-icons/fa';
import { GoVerified } from 'react-icons/go';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Helmet } from 'react-helmet-async';
import styles from './styles.module.css';
import AudioShot from '../../../../components/audioShot';
import DrawerNavbar from '../../../../components/navbar/drawerNavbar';
import { feedByUserIdApi, getUserByIdApi } from '../../../../utils/api';
import TopDownloadRedirect from '../../../../components/topDownloadRedirect';
import SignInOptionsCard from '../../../../components/signInOptionsCard';
import { setSignInOptionsVisible } from '../../../../redux/actions/authActions';
import LoadingBar from '../../../../components/loadingBar';
import ErrorComponent from '../../../../components/errorComponent';
import MiddleContainerHeader from '../../../../components/middleContainerHeader';
import FeedEnd from '../../../../components/feedEnd';

export default function Profile() {
    const { userId } = useParams();
    // const query = new URLSearchParams(useLocation().search);
    const { showSignInOptions, isLoggedIn } = useSelector((state) => state).authReducer;
    const dispatch = useDispatch();

    const [isFollower, setFollower] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [profileInfo, setProfileInfo] = useState(null);
    const [profileAudioShots, setProfileAudioShots] = useState([]);

    // const uid = query.get("uid");

    let drawerNavbar;
    let signInOptions;
    let data = [];

    if (isDrawerOpen) {
        drawerNavbar = <DrawerNavbar click={() => setDrawerOpen(!isDrawerOpen)} />;
    }
    if (showSignInOptions) {
        signInOptions = <SignInOptionsCard style={{ display: 'block' }} />;
    }

    const getProfileFeed = () => {
        const offsetId =
            profileAudioShots.length !== 0
                ? profileAudioShots[profileAudioShots.length - 1].id
                : '';
        feedByUserIdApi(userId, offsetId)
            .then((r) => {
                if (r.length === 0) {
                    setHasMore(false);
                    setIsLoading(false);
                    return;
                }
                data = profileAudioShots.concat(r);
                setProfileAudioShots(data);
            })
            .catch((err) => console.error(err));
    };

    useEffect(async () => {
        getUserByIdApi(userId)
            // eslint-disable-next-line no-shadow
            .then((data) => setProfileInfo(data))
            .catch((err) => console.error(err));
        getProfileFeed();
        // console.log(profile);
        // console.log(profileAudioShots);
    }, []);

    if (profileInfo === null) {
        return <LoadingBar />;
    }
    if (profileInfo === 404) {
        return <ErrorComponent errorMsg="Profile not found Error" />;
    }
    document.title = profileInfo.name;
    return (
        <div className={styles.profileContainer}>
            <Helmet
                meta={[
                    {
                        name: 'twitter:card',
                        content: 'summary_large_image'
                    },
                    { name: 'twitter:title', content: profileInfo.name },
                    { name: 'twitter:image', content: profileInfo.picture },
                    { property: 'og:title', content: profileInfo.name },
                    { property: 'og:image', content: profileInfo.picture },
                    {
                        property: 'og:url',
                        content: `https://justbaat.com/app/p/${userId}`
                    }
                ]}
            />
            <TopDownloadRedirect />
            <MiddleContainerHeader
                header={profileInfo.name}
                onClick={() => setDrawerOpen(!isDrawerOpen)}
                rightIcon={profileInfo.verified && <GoVerified size={25} color="#1C9DEA" />}
                id="top"
            />
            <div className={styles.profileContent}>
                <img className={styles.profilePic} alt="Profile Pic" src={profileInfo.picture} />
                <div className={styles.profileName}>{profileInfo.name}</div>
                <div className={styles.profileFollow}>
                    <div className={styles.profileFollowers}>
                        <label>{profileInfo.totalFollowers ? profileInfo.totalFollowers : 0}</label>
                        <span>Followers</span>
                    </div>
                    <div className={styles.profileFollowers}>
                        <label>{profileInfo.totalFollwing ? profileInfo.totalFollwing : 0}</label>
                        <span>Following</span>
                    </div>
                </div>
                <button
                    className={styles.followButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        setFollower(!isFollower);
                        if (!isLoggedIn) {
                            dispatch(setSignInOptionsVisible(true));
                        }
                    }}
                >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {!isLoggedIn ? 'Follow' : isFollower ? 'Unfollow' : 'Follow'}
                </button>
                <p className={styles.profileHeaderText}>{profileInfo.whyListener}</p>
                {profileInfo.scheduledText && (
                    <label className={styles.profileHeaderLabel}>{profileInfo.scheduledText}</label>
                )}
                <div className={styles.profileBio}>{profileInfo.bio}</div>
                <div className={styles.profileConnect}>
                    <label>Connect with me at:</label>
                    <div className={styles.profileExternalLinks}>
                        {profileInfo.youtube && (
                            <span onClick={() => window.open(profileInfo.youtube, '_blank')}>
                                <FaYoutube size="1.5em" color="red" />
                            </span>
                        )}
                        {(profileInfo.facebook || profileInfo.facebook !== '') && (
                            <span onClick={() => window.open(profileInfo.facebook, '_blank')}>
                                <FaFacebook size="1.5em" color="#1a76f6" />
                            </span>
                        )}
                        {/* {profileInfo["insta"] &&
                            <span onClick={() => window.open('https://twitter.com/Justbaat', '_blank')}>
                                <FaTwitter size={30} color={"deepskyblue"}/>
                            </span>} */}
                        {profileInfo.insta && (
                            <span
                                onClick={() =>
                                    window.open(
                                        `https://www.instagram.com/${profileInfo.insta}/`,
                                        '_blank'
                                    )
                                }
                            >
                                <FaInstagram size="1.5em" />
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.profileAudioShots}>
                {profileAudioShots.length !== 0 && (
                    <div className={styles.profileAudioShotsHeader}>
                        <FaHeadphonesAlt size="1.5em" />
                        <h3>Audio Shots</h3>
                    </div>
                )}
                {/* eslint-disable-next-line no-nested-ternary */}
                {profileAudioShots.length !== 0 ? (
                    <InfiniteScroll
                        className={styles.audioShots}
                        dataLength={profileAudioShots.length}
                        next={getProfileFeed}
                        hasMore={hasMore}
                        loader={<LoadingBar />}
                        scrollThreshold={0.95}
                        endMessage={<FeedEnd text="End of Feed" />}
                    >
                        {profileAudioShots.map((audioShot, idx) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <AudioShot key={idx} index={idx} data={audioShot} isLiked={false} />
                            );
                        })}
                    </InfiniteScroll>
                ) : isLoading ? (
                    <LoadingBar />
                ) : (
                    ''
                )}
            </div>
            {signInOptions}
            {drawerNavbar}
        </div>
    );
}
