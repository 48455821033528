import { ActionTypes } from '../types/authActionTypes';

const initialState = {
    users: [],
    showSignInOptions: false,
    isLoggedIn: false
};

// eslint-disable-next-line default-param-last,import/prefer-default-export
export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_USERS: {
            return { ...state, ...payload };
        }
        case ActionTypes.SET_SIGNIN_OPTIONS_VISIBLE: {
            return { ...state, ...payload };
        }
        case ActionTypes.SET_IS_LOGGEDIN: {
            return { ...state, ...payload };
        }
        default: {
            return state;
        }
    }
};
