import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './styles.module.css';
import DrawerNavbar from '../../../../components/navbar/drawerNavbar';
import AudioShot from '../../../../components/audioShot';
import { shotsByRecomededApi } from '../../../../utils/api';
import TopDownloadRedirect from '../../../../components/topDownloadRedirect';
import LoadingBar from '../../../../components/loadingBar';
import SignInOptionsCard from '../../../../components/signInOptionsCard';
import FeedEnd from '../../../../components/feedEnd';
import MiddleContainerHeader from '../../../../components/middleContainerHeader';

export default function Recommended() {
    const { showSignInOptions } = useSelector((state) => state).authReducer;

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [recData, setRecData] = useState([]);
    let drawerNavbar;
    let signInOptions;
    let data = [];

    if (isDrawerOpen) {
        drawerNavbar = <DrawerNavbar click={() => setDrawerOpen(!isDrawerOpen)} />;
    }
    if (showSignInOptions) {
        signInOptions = <SignInOptionsCard style={{ display: 'block' }} />;
    }

    const fetchData = () => {
        const offsetId = recData.length !== 0 ? recData[recData.length - 1].id : '';
        shotsByRecomededApi(offsetId)
            .then((r) => {
                if (r.length === 0) {
                    setHasMore(false);
                    return;
                }
                data = recData.concat(r);
                setRecData(data);
                // console.log(data);
                // let uniqueData = data.filter((thing, index, self) =>
                //         index === self.findIndex((t) => (
                //             t.id === thing.id
                //         ))
                // );
                // if (uniqueData.length === recData.length) {
                //     setHasMore(false);
                // }
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        document.title = 'Recommended Audio Shots';
        fetchData();

        return setRecData([]);
    }, []);

    return (
        <div className={styles.recommendedContainer}>
            <TopDownloadRedirect />
            <MiddleContainerHeader
                header="Recommended"
                onClick={() => setDrawerOpen(!isDrawerOpen)}
                id="top"
            />
            {recData.length !== 0 ? (
                <InfiniteScroll
                    className={styles.recommendedContent}
                    dataLength={recData.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoadingBar />}
                    scrollThreshold={0.95}
                    endMessage={<FeedEnd text="You Have Reached End of Your Feed" />}
                >
                    {/* eslint-disable-next-line no-shadow */}
                    {recData.map((data, i) => {
                        // eslint-disable-next-line react/no-array-index-key
                        return <AudioShot key={i} index={i} data={data} isLiked={false} />;
                    })}
                </InfiniteScroll>
            ) : (
                <LoadingBar />
            )}
            {signInOptions}
            {drawerNavbar}
        </div>
    );
}
