import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { logo2 } from '../../utils/constants';

function MiddleContainerHeader({ header, rightIcon, onClick }) {
    return (
        <div className={styles.feedContainerHeader}>
            <div className={styles.mobileNav}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button onClick={onClick}>
                    <GiHamburgerMenu size={25} color="#1C9DEA" />
                </button>
                <Link to="/home">
                    <img alt="logo" src={logo2} height={30} />
                </Link>
            </div>
            <div className={styles.header}>
                <h2>{header}</h2>
                {rightIcon}
            </div>
        </div>
    );
}

export default MiddleContainerHeader;
