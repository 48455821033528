import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Wave from '@foobar404/wave';
import styles from './styles.module.css';
import AxiosApi from '../../../../../services/axios-api';
import { Loading } from '../../../../../components/@embed';
import CircularPlayButton from '../../../../../components/@embed/circularPlayButton';
import { fancyTimeFormat, timeStamp, useQuery } from '../../../../../utils';
import { widgetClicked, widgetShown } from '../../../../../utils/analytics';

// http://localhost:3000/embed/podcast-player-1?packageId=SeC99haxHiCqqLaWjOgV&appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST
// http://localhost:3000/embed/podcast-player-1?packageId=TS9sWrSjIvX6wPsr6Jun&appId=4e81a04a-133f-44eb-8230-0f5df3529bc5&secret=2b494e84358ca0edb53a7c1e389559b637f8e08e67606b4f53cc6ba295835159&partner_id=JUSTBAAT_TEST&baseUrl=https%3A%2F%2Fjustbaat-debug.web.app&config=type%3Dcard-1%26width%3D800px%26openInMiniPlayer%3Dfalse%26containerId%3Djb-podcast-container
// http://localhost:3000/embed/podcast-player-1?appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST&baseUrl=https%3A%2F%2Fjustbaat-debug.web.app&config=type%3Dcard-1%26width%3D800px%26openInMiniPlayer%3Dfalse%26containerId%3Djb-podcast-container
function PodcastPlayer1() {
    const [wave] = useState(new Wave());
    const [audioShot, setAudioShot] = useState(null);
    const [isPaused, setIsPaused] = useState(false);

    const query = useQuery();

    const packageId = query.get('packageId');
    const appId = query.get('appId');
    const secret = query.get('secret');
    const partner_id = query.get('partner_id');
    const config = useQuery({ search: query.get('config') });
    const bgColor = config.get('backgroundColor');

    const audioRef = useRef();

    useEffect(() => {
        AxiosApi.post(
            'cloud_function',
            `/sdk_api/v1/widgets/getWidgetAudioShot`,
            'getWidgetAudioShot',
            {
                partner_id,
                appId,
                secret,
                packageId
            }
        )
            .then(({ data }) => {
                if (data.status === 'success') {
                    setAudioShot(data.data);
                    widgetShown(data.data.id);
                }
            })
            .catch(() => {
                AxiosApi.post('cloud_function', `/sdk_api/v1/audio-shots/getById`, 'getById', {
                    partner_id,
                    appId,
                    secret,
                    audioShotId: packageId
                })
                    .then(({ data }) => {
                        if (data.status === 'success') {
                            setAudioShot(data.data);
                            widgetShown(data.data.id);
                        }
                    })
                    .catch(() => {
                        AxiosApi.post(
                            'cloud_function',
                            `/sdk_api/v1/package/justbaatCreator`,
                            'getById',
                            {
                                partner_id,
                                appId,
                                secret
                            }
                        ).then(({ data }) => {
                            if (data.status === 'success') {
                                setAudioShot(data.data[0]);
                                widgetShown(data.data[0].id);
                            }
                        });
                    });
            });
    }, []);

    if (!audioShot)
        return (
            <div
                style={{ '--jb-card-bg-color': bgColor || '#094794' }}
                className={styles['jb-podcast-card-1']}
            >
                <Loading />
            </div>
        );

    const showWave = () => {
        try {
            wave.fromElement(`jb-audio-${audioShot?.id}`, `jb-podcast-waveform-${audioShot.id}`, {
                type: 'bars',
                colors: ['#fff'],
                stroke: 2
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };

    const setPlay = (p) => {
        widgetClicked(audioShot.id);
        if (!audioRef.current?.duration) {
            audioRef.current?.load();
            showWave();
        }
        setIsPaused(p);
    };

    return (
        <div
            style={{ '--jb-card-bg-color': bgColor || '#094794' }}
            className={styles['jb-podcast-card-1']}
            onClick={() => widgetClicked(audioShot.id)}
        >
            <img className={styles['jb-podcast-card-1-cover']} src={audioShot?.image} alt="logo" />
            <img
                className={styles['jb-podcast-card-1-branding']}
                src="/@embed/img/justbaat.svg"
                alt="logo"
            />
            <div className={styles['jb-podcast-card-1-content']}>
                <Typography component="p" variant="subtitle1">
                    {audioShot?.title}
                </Typography>
                <Typography component="span" variant="body2">
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {timeStamp(audioShot?.created_date?._seconds)} •{' '}
                    {audioShot?.creatorName || audioShot?.userName}
                    {audioShot?.duration &&
                        ` • 
                    ${fancyTimeFormat(audioShot?.duration)}`}
                </Typography>
            </div>
            <canvas
                className={styles['jb-podcast-waveform']}
                id={`jb-podcast-waveform-${audioShot?.id}`}
            />
            <video
                ref={audioRef}
                crossOrigin="anonymous"
                loop
                id={`jb-audio-${audioShot?.id}`}
                src={audioShot?.src}
                playsInline
                preload="none"
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '4.5%',
                    right: '1.5%'
                }}
            >
                <CircularPlayButton
                    data={audioShot}
                    config={{ color: bgColor || '#094794' }}
                    audioRef={audioRef}
                    isPaused={isPaused}
                    setIsPaused={setPlay}
                />
            </Box>
        </div>
    );
}

export default PodcastPlayer1;
