import { ActionTypes } from '../types/audioShotsActionTypes';

export const setUser = (users) => {
    return {
        type: ActionTypes.SET_USERS,
        payload: { users }
    };
};

export const setSelectedAudioShot = (audioShot) => {
    return {
        type: ActionTypes.SELECTED_AUDIO_SHOT,
        payload: {
            selectedAudioShot: audioShot
        }
    };
};

export const setSelectedReply = (reply) => {
    return {
        type: ActionTypes.SELECTED_REPLY,
        payload: {
            selectedReply: reply
        }
    };
};

export const setIsAuioShotPlaying = (isPlaying) => {
    return {
        type: ActionTypes.SET_IS_PLAYING,
        payload: {
            isAudioShotPlaying: isPlaying
        }
    };
};

export const setAudioShotQueue = (queue) => {
    return {
        type: ActionTypes.SELECTED_AUDIO_SHOT,
        payload: {
            audioShotQueue: queue
        }
    };
};
