import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './styles.module.css';
import { fancyTimeFormat, paginate, timeStamp, useQuery } from '../../../../../utils';
import AxiosApi from '../../../../../services/axios-api';
import { CircularPlayButton, Loading } from '../../../../../components/@embed';
import { widgetClicked, widgetShown } from '../../../../../utils/analytics';

// http://localhost:3000/embed/podcast-list-template-2?packageId=SeC99haxHiCqqLaWjOgV&appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST
// http://localhost:3000/embed/podcast-list-template-2?appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST
// http://localhost:3000/embed/podcast-list-template-1?packageId=TS9sWrSjIvX6wPsr6Jun&appId=4e81a04a-133f-44eb-8230-0f5df3529bc5&secret=2b494e84358ca0edb53a7c1e389559b637f8e08e67606b4f53cc6ba295835159&partner_id=JUSTBAAT_TEST
function PodcastList2() {
    const [audioShots, setAudioShots] = useState(null);
    const [allAudioShots, setAllAudioShots] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const contentPage = useRef(0);

    const query = useQuery();
    const packageId = query.get('packageId');
    const appId = query.get('appId');
    const secret = query.get('secret');
    const partner_id = query.get('partner_id');

    const config = useQuery({ search: query.get('config') });
    const bgColor = config.get('backgroundColor');
    const pageSize = config.get('pageSize');

    const pageNext = async () => {
        contentPage.current += 1;
        if (contentPage.current < allAudioShots.length) {
            setAudioShots([...audioShots, ...allAudioShots[contentPage.current]]);
        } else setHasMore(false);
    };

    useEffect(() => {
        AxiosApi.post('cloud_function', `/sdk_api/v1/widgets/getWidgetData`, 'getWidgetData', {
            partner_id,
            appId,
            secret,
            packageId
        })
            .then((res) => {
                if (res.data.status === 'success') {
                    const cards = paginate(res.data.data.cards, Number(pageSize) || 3);
                    setAllAudioShots(cards);
                    setAudioShots(cards[0]);
                }
            })
            .catch(() => {
                AxiosApi.post('cloud_function', `/sdk_api/v1/package/justbaatCreator`, 'getById', {
                    partner_id,
                    appId,
                    secret
                }).then(({ data }) => {
                    if (data.status === 'success') {
                        const cards = paginate(data.data);
                        setAudioShots(cards[0]);
                        setAllAudioShots(cards);
                    }
                });
            });
    }, []);

    if (!audioShots)
        return (
            <div className={styles['jb-playlist-template-2']}>
                <Loading />
            </div>
        );

    return (
        <Box
            id="jb-playlist-template-2-container"
            sx={{
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
                '&::-webkit-scrollbar': {
                    display: 'none'
                }
            }}
        >
            <InfiniteScroll
                dataLength={audioShots.length}
                next={pageNext}
                hasMore={hasMore}
                loader={<Loading />}
                scrollThreshold={0.8}
                id="jb-playlist-template-2"
                className={styles['jb-playlist-template-2']}
                style={{ '--jb-card-bg-color': bgColor || '#009E78' }}
                // endMessage={
                //     <Stack alignItems="center" justifyContent="center">
                //         <Typography variant="subtitle1">End</Typography>
                //     </Stack>
                // }
                scrollableTarget="jb-playlist-template-2-container"
            >
                {audioShots.map((audioShot) => (
                    <PodcastList2Item key={audioShot.id} audioShot={audioShot} bgColor={bgColor} />
                ))}
                <button
                    id="jb-podcast-template-2-iframe-load-more-button"
                    onClick={(e) => {
                        document.getElementById('jb-playlist-template-2-container').style.overflow =
                            'auto';
                        e.target.style.display = 'none';

                        window.parent.postMessage(
                            { type: 'template-2-load-more' },
                            window.location !== window.parent.location
                                ? document.referrer
                                : document.location.href
                        );
                    }}
                    style={{ color: bgColor || '#009E78' }}
                    className={styles['jb-podcast-template-2-iframe-load-more-button']}
                >
                    Load More
                </button>
            </InfiniteScroll>
        </Box>
    );
}

function PodcastList2Item({ audioShot, bgColor }) {
    const audioRef = useRef();
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => widgetShown(audioShot.id), []);

    const setPlay = (p) => {
        widgetClicked(audioShot.id);
        if (!audioRef.current?.duration) {
            audioRef.current?.load();
        }
        setIsPaused(p);
    };

    return (
        <div
            className={styles['jb-playlist-template-2-item']}
            onClick={() => widgetClicked(audioShot.id)}
        >
            <div className={styles['jb-playlist-template-2-item-cover']}>
                <img src={audioShot?.image} alt="logo" />
            </div>
            <div className={styles['jb-playlist-template-2-item-content']}>
                <Typography component="p" variant="subtitle1">
                    {audioShot?.title}
                </Typography>
                <Typography component="span" variant="body2">
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {timeStamp(audioShot?.created_date._seconds)} •{' '}
                    {audioShot?.creatorName || audioShot?.userName}
                    {audioShot?.duration &&
                        ` • 
                    ${fancyTimeFormat(audioShot?.duration)}`}
                </Typography>
            </div>
            <div className={styles.playButton}>
                <video
                    ref={audioRef}
                    loop
                    id="jb-audio"
                    src={audioShot?.src}
                    playsInline
                    preload="none"
                />
                <CircularPlayButton
                    data={audioShot}
                    audioRef={audioRef}
                    config={{ color: bgColor || '#009E78', size: '6vw' }}
                    isPaused={isPaused}
                    setIsPaused={setPlay}
                />
            </div>
        </div>
    );
}

export default PodcastList2;
