// import { GlobalsContext } from '../../AppContext';
// import { adVendors } from '../../utils';

import { useState, useRef, useEffect } from 'react';

const now = Date.now();
const elementId = `div-jio-ad-${now}`;

const adspotKey = 'q803bgd4';
const source = 'justbaat.com';

function JioAd() {
    const [loaded, setLoaded] = useState(false);

    // const { renderedGptSlots, setRenderedGptSlots } = useContext(GlobalsContext);

    const adContainerRef = useRef(null);

    const initJioAd = () => {
        /* @ts-ignore */
        // window.googletag = window.googletag || { cmd: [] };

        // const vendor = adVendors['jio'];

        const isScriptExisting = !!document.getElementById('jb-jio-ad');

        if (!isScriptExisting) {
            const script = document.createElement('script');

            script.src =
                'https://mercury.akamaized.net/jioads/websdk/default/stable/jioAds.js';
            script.id = 'jb-jio-ad';
            script.async = true;

            document.head.appendChild(script);

            /* @ts-ignore */
            script.onload = () => {
                setLoaded(true);
            };
        } else {
            setLoaded(true);
        }
    };

    useEffect(() => {
        initJioAd();

        // setRenderedGptSlots({ [elementId]: true });
    }, []);

    return (
        <div id={`${elementId}-wrapper`} ref={adContainerRef}>
            {loaded && (
                <ins
                    id={elementId}
                    data-adspot-key={adspotKey}
                    data-source={source}
                />
            )}
        </div>
    );
}

export default JioAd;
