import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';

export default function ErrorComponent({ errorMsg }) {
    const history = useHistory();

    return (
        <div className={styles.container}>
            <div className={styles.errorCard}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button onClick={() => history.goBack()} />
                <p>{errorMsg}</p>
            </div>
        </div>
    );
}
