import React, { useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import Wave from '@foobar404/wave';
import styles from './styles.module.css';
import { fancyTimeFormat, paginate, useQuery } from '../../../../../utils';
import AxiosApi from '../../../../../services/axios-api';
import { CircularPlayButton, Loading } from '../../../../../components/@embed';
import { logo } from '../../../../../utils/constants';
import { widgetClicked, widgetShown } from '../../../../../utils/analytics';

// http://localhost:3000/embed/podcast-list-template-1?packageId=SeC99haxHiCqqLaWjOgV&appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST
// http://localhost:3000/embed/podcast-list-template-1?appId=5b2f94b7-7f4a-4384-bf0d-c44616152a9f&secret=49bc0e9e4aaae0b90b061643d4a909a9e19812d38286c34384109186b6b54e7c&partner_id=JUSTBAAT_TEST
// http://localhost:3000/embed/podcast-list-template-1?packageId=TS9sWrSjIvX6wPsr6Jun&appId=4e81a04a-133f-44eb-8230-0f5df3529bc5&secret=2b494e84358ca0edb53a7c1e389559b637f8e08e67606b4f53cc6ba295835159&partner_id=JUSTBAAT_TEST
// http://localhost:3000/embed/podcast-list-template-1?appId=4c44d73c-6667-4eb5-8ac7-c120644bbbea&secret=3c7abd650cced3239534746bfdc27fe15c07423c8c38acaac7bd285cba065b0f&partner_id=AAJ_TAK&baseUrl=http%3A%2F%2Flocalhost%3A3000&config=type%3Dtemplate-1%26width%3D300px%26pageSize%3D4%26containerId%3Djb-podcast-container-3
function PodcastList1() {
    const [audioShots, setAudioShots] = useState(null);
    const [allAudioShots, setAllAudioShots] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const contentPage = useRef(0);

    const query = useQuery();
    const packageId = query.get('packageId');
    const appId = query.get('appId');
    const secret = query.get('secret');
    const partner_id = query.get('partner_id');

    const config = useQuery({ search: query.get('config') });
    const pageSize = config.get('pageSize');

    const pageNext = async () => {
        contentPage.current += 1;
        if (contentPage.current < allAudioShots.length) {
            setAudioShots([...audioShots, ...allAudioShots[contentPage.current]]);
        } else setHasMore(false);
    };

    useEffect(() => {
        AxiosApi.post('cloud_function', `/sdk_api/v1/widgets/getWidgetData`, 'getWidgetData', {
            partner_id,
            appId,
            secret,
            packageId
        })
            .then((res) => {
                if (res.data.status === 'success') {
                    const cards = paginate(res.data.data.cards, pageSize > 3 ? pageSize : 3);
                    setAllAudioShots(cards);
                    setAudioShots(cards[0]);
                }
            })
            .catch(() => {
                AxiosApi.post('cloud_function', `/sdk_api/v1/package/justbaatCreator`, 'getById', {
                    partner_id,
                    appId,
                    secret
                }).then(({ data }) => {
                    if (data.status === 'success') {
                        const cards = paginate(data.data, pageSize > 3 ? pageSize : 3);
                        setAudioShots(cards[0]);
                        setAllAudioShots(cards);
                    }
                });
            });
    }, []);

    if (!audioShots)
        return (
            <div className={styles['jb-playlist-template-1']}>
                <Loading />
            </div>
        );

    return (
        <div className={styles['jb-playlist-template-1']}>
            <div className={styles['jb-playlist-template-1-header']}>
                <p />
                <img src={logo} alt="logo" />
            </div>
            <div className={styles['jb-playlist-template-1-content']} id="jb-playlist-template-1">
                <InfiniteScroll
                    dataLength={audioShots.length}
                    next={pageNext}
                    hasMore={hasMore}
                    loader={<Loading />}
                    scrollThreshold={0.8}
                    endMessage={
                        <Stack alignItems="center" justifyContent="center">
                            <Typography variant="subtitle1">...</Typography>
                        </Stack>
                    }
                    scrollableTarget="jb-playlist-template-1"
                >
                    {audioShots.map((audioShot) => (
                        <PodcastList1Item key={audioShot.id} audioShot={audioShot} />
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
}

function PodcastList1Item({ audioShot }) {
    const [isPaused, setIsPaused] = useState(false);
    const [wave] = useState(new Wave());
    const container = useRef();
    const audioRef = useRef();
    const loaderRef = useRef(null);

    useEffect(() => widgetShown(audioShot.id), []);

    const showWave = () => {
        try {
            wave.fromElement(`jb-audio-${audioShot?.id}`, `jb-podcast-waveform-${audioShot.id}`, {
                type: 'bars',
                colors: ['#fff'],
                stroke: 2
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    };

    const loader = () => {
        if (loaderRef.current) {
            if (!isPaused && !audioRef.current?.duration) {
                // loaderRef.current.style.display = 'block';
                loaderRef.current.style.display = 'none';
            } else {
                loaderRef.current.style.display = 'none';
            }
        }
    };

    const setPlay = (p) => {
        widgetClicked(audioShot.id);
        if (!audioRef.current?.duration) {
            audioRef.current?.load();
            showWave();
            loader();
        }
        setIsPaused(p);
    };

    return (
        <div
            ref={container}
            className={styles['jb-playlist-template-1-item']}
            onClick={() => setPlay(!isPaused)}
        >
            <div className={styles['jb-podcast-item-cover']}>
                <div ref={loaderRef} className={styles.loader} />
                {/* eslint-disable-next-line no-nested-ternary */}
                {audioShot?.src.includes('mp4') && !audioShot?.audioOnly ? (
                    <video
                        ref={audioRef}
                        crossOrigin="anonymous"
                        loop
                        id={`jb-audio-${audioShot?.id}`}
                        src={audioShot?.src}
                        poster={audioShot?.image}
                        playsInline
                        preload="none"
                    />
                ) : (
                    <>
                        <video
                            ref={audioRef}
                            loop
                            id={`jb-audio-${audioShot?.id}`}
                            crossOrigin="anonymous"
                            src={audioShot?.src}
                            playsInline
                            preload="none"
                        />
                        <img src={audioShot?.image} alt="cover" loading="lazy" />
                    </>
                )}
                <canvas
                    className={styles['jb-podcast-waveform']}
                    id={`jb-podcast-waveform-${audioShot?.id}`}
                />
            </div>
            <div className={styles['jb-playlist-template-1-item-content']}>
                <p>{audioShot?.title}</p>
                <span>
                    {/* eslint-disable-next-line no-underscore-dangle */}
                    {/* {timeStamp(audioShot?.created_date._seconds)} •{' '} */}
                    {audioShot?.creatorName || audioShot?.userName || 'JustBaat Creators'}
                    {audioShot?.duration &&
                        ` • 
                    ${fancyTimeFormat(audioShot?.duration)}`}
                </span>
                <Box>
                    <CircularPlayButton
                        data={audioShot}
                        audioRef={audioRef}
                        config={{ color: '#EA443F', size: '100%' }}
                        isPaused={isPaused}
                        setIsPaused={setPlay}
                    />
                </Box>
            </div>
            {audioShot?.isAd && (
                <p className={styles['jb-playlist-template-1-item-sponsor']}>
                    Sponsored by <b>JustBaat</b>
                </p>
            )}
        </div>
    );
}

export default PodcastList1;
